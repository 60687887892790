import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";

import Stepper from "./Stepper";
import Buttons from "./Buttons";
import Header from "./Header";
import Rating from "./Rating";
import removalsActions from "../../../../redux/actions/removals";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#ff4444" },
  },
});

export default function RemovalCard({
  removal,
  setRemoval,
  setViewRemoval,
  setCoordinateRemoval,
}) {
  const [viewState, setViewState] = React.useState(false);
  const [ratingTemp, setRatingTemp] = React.useState(0);
  const [status, setStatus] = React.useState(0);

  const [newDateDatetimeRemoval, setNewDateDatetimeRemoval] = React.useState("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (removal) {
      setStatus(removal.status);
      setNewDateDatetimeRemoval(new Date(removal.datetimeRemoval))
    }
  }, [removal]);

  const handleDeleteRemoval = async (removalID,localID) => {
    await dispatch(removalsActions.deleteRemovalInProgress({ removalID, localID }));
  };
  const compareDates = () =>{
    let dateNow = new Date();
    if(((newDateDatetimeRemoval.getTime() - dateNow.getTime())/3600)<44000){
      return true
    } else {
      return false
    }
  }

  return (
    <Box boxShadow={3}>
      <Card>
        <CardContent>
          <Header status={status} removal={removal} ratingTemp={ratingTemp} />
          <Grid container spacing={2}>
            {viewState && (
              <Grid item xs={12}>
                <hr></hr>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  Estado del retiro
                </Typography>
                <Stepper removal={removal} status={status} />
                <hr />
                <MuiThemeProvider theme={theme}>
                  <Button
                    color="primary"
                    fullWidth
                    startIcon={<DeleteIcon />}
                    size="small"
                    disabled={compareDates()}
                    onClick={() => handleDeleteRemoval(removal._id,removal.localID)}
                  >
                    Cancelar retiro 
                  </Button>
                </MuiThemeProvider>
                <hr />
              </Grid>
            )}
            {status === 4 && removal.rating === 0 && (
              <Rating
                ratingTemp={ratingTemp}
                setRatingTemp={setRatingTemp}
                removal={removal}
              />
            )}
            <Buttons
              status={status}
              removal={removal}
              setRemoval={setRemoval}
              viewState={viewState}
              setViewState={setViewState}
              setViewRemoval={setViewRemoval}
              setCoordinateRemoval={setCoordinateRemoval}
            />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

RemovalCard.propTypes = {
  removal: PropTypes.object.isRequired,
  setRemoval: PropTypes.func.isRequired,
  setViewRemoval: PropTypes.func.isRequired,
  setCoordinateRemoval: PropTypes.func.isRequired,
};
