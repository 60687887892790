import React from "react";
import { Grid, Card, CardContent, Box } from "@material-ui/core";
import Buttons from "./Buttons";
import Header from "./Header";
import Rating from "./Rating";
import Body from "./Body";
import PropTypes from "prop-types";

export default function RemovalCard({ removal }) {
  const [viewState, setViewState] = React.useState(false);
  const [ratingTemp, setRatingTemp] = React.useState(0);
  const [status, setStatus] = React.useState(0);

  React.useEffect(() => {
    if (removal) {
      setStatus(removal.status);
    }
  }, [removal]);

  return (
    <Box boxShadow={3}>
      <Card>
        <CardContent>
          <Header removal={removal} />
          <Grid container spacing={2}>
            {viewState && (
              <Body setViewState={setViewState} removal={removal} />
            )}
            {status === 4 && removal.rating === 0 && (
              <Rating
                ratingTemp={ratingTemp}
                setRatingTemp={setRatingTemp}
                removal={removal}
              />
            )}
            <Buttons
              removal={removal}
              viewState={viewState}
              setViewState={setViewState}
            />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

RemovalCard.propTypes = {
  removal: PropTypes.object.isRequired,
  setRemoval: PropTypes.func.isRequired,
  setViewRemoval: PropTypes.func.isRequired,
  setCoordinateRemoval: PropTypes.func.isRequired,
};
