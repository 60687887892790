import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import PropTypes from "prop-types";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#00C851",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#00C851",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  trackAlert: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#ffbb33",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, alert, checked, onChange }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: alert && !checked ? classes.trackAlert : classes.track,
        checked: classes.checked,
      }}
      checked={checked}
      onChange={onChange}
    />
  );
});

export default function CustomizedSwitches({ value, label, alert, onChange }) {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <IOSSwitch checked={value} onChange={onChange} alert={alert} />
        }
        label={label}
      />
    </FormGroup>
  );
}

CustomizedSwitches.propTypes = {
  value: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  alert: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
