import React from "react";
import { Grid, Typography } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PropTypes from "prop-types";

const days = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];
const months = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

const windows = {
  A: "entre 9:00 y 13:00 hrs",
  B: "entre 14:00 y 19:00 hrs",
};

export default function Header({ removal }) {
  const blueBackgorund =
    "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)";
  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography component="h2" variant="h6">
            Retiro extra solicitado
          </Typography>
        </Grid>
        <Grid item>
          <DateRangeIcon style={{ color: "#0099CC" }} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <hr style={{ background: blueBackgorund, height: 2 }} /> */}
          <hr style={{ background: "linear-gradient(34deg, rgba(43,43,43,1) 0%, rgba(255,121,0,1) 29%, rgba(248,255,0,1) 92%)", height: 2 }} />
          <Typography variant="body2" align="center" color="textSecondary">
            Fecha y horario solicitado
          </Typography>
          <Typography variant="body2" align="center">
            {days[new Date(removal.datetimeRemoval).getDay()] +
              " " +
              new Date(removal.datetimeRemoval).getDate() +
              " de " +
              months[new Date(removal.datetimeRemoval).getMonth()] +
              " " +
              new Date(removal.datetimeRemoval).getFullYear()}
          </Typography>
          <Typography variant="body2" align="center">
            {windows[removal.window]}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

Header.propTypes = {
  removal: PropTypes.object.isRequired,
};
