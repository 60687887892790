import Axios from "../../api/Axios";

const fetch = ({ localID }) => {
  return (dispatch) => {
    Axios.get("/webApp/reports", { params: { localID } })
      .then((response) => {
        dispatch({ type: "FETCH_REPORTS", payload: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const fetchSinaderReports = ({ localID }) => {
  return (dispatch) => {
    Axios.get("/webApp/sinaderReports", { params: { localID } })
      .then((response) => {
        dispatch({ type: "FETCH_REPORTS", payload: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export default {
  fetch,
  fetchSinaderReports,
};
