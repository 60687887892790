  import React from "react";
import Axios from "../api/Axios";
import { useAppContext } from "./AppContext";
const DashboardContext = React.createContext();
Axios.defaults.timeout = 75000;

export function DashboardContextProvider(props) {
  const { userData, userType, setLoading } = useAppContext();

  const dateNow = new Date();

  const [dateInit, setDateInit] = React.useState(
    new Date(dateNow.getFullYear(), dateNow.getMonth(), 1)
  );
  const [dateFinish, setDateFinish] = React.useState(
    new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0, 23, 59, 59)
  );
  const [dashboardData, setDashboardData] = React.useState({});
  const [selectDate, setSelectDate] = React.useState(0);

  // ESTE EFECTO DEBE ELIMINARSE. ESTÁ REPARANDO UN ERROR DE EJECUCIÓN

  React.useEffect(() => {
    setLoading(true);
    const dates = { dateInit, dateFinish };
    getDashboard({ dates, setDashboardData });
    setLoading(false);
  }, [dateInit, dateFinish, setLoading]);

  const value = React.useMemo(() => {
    return {
      dashboardData,
      userData,
      dateInit,
      dateFinish,
      setDateInit,
      setDateFinish,
      userType,
      selectDate,
      setSelectDate,
    };
  }, [dashboardData, userData, dateInit, dateFinish, userType, selectDate]);

  return <DashboardContext.Provider value={value} {...props} />;
}

export function useDashboardContext() {
  const context = React.useContext(DashboardContext);

  if (!context) {
    throw new Error("NO SUSCRITO AL CONTEXTO");
  }
  return context;
}

const getDashboard = async ({ dates, setDashboardData }) => {
  try {
    const response = await Axios.get("/webApp/dashboard", {
      params: { ...dates },
    });
    setDashboardData(response.data);
  } catch (err) {
    setDashboardData({});
  }
};

// const getValors = async () => await Axios.get('https://mindicador.cl/api')
