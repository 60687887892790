import React from "react";
import { TextField, Button, Paper, makeStyles } from "@material-ui/core";
import colorLogo from "../../resources/images/logo-color.png";
import whiteLogo from "../../resources/images/logo.png";

import { useAppContext } from "../../context/AppContext";
const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: spacing(3),
    marginRight: spacing(3),
    [breakpoints.up(400 + spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
    borderRadius: "6px",
  },
  paper: {
    marginTop: spacing(18),
    marginBottom: spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${spacing(2)}px ${spacing(3)}px ${spacing(3)}px`,
    borderRadius: "8px",
  },
}));

export default function Login({ login }) {
  const styles = useStyles();

  const { loginStatus, themeColor } = useAppContext();
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });

  const updateData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      login({ email: formData.email, password: formData.password });
    }
  };

  const goReset = () => {
    var red = window.location.origin + "/recovery";
    console.log(window.location);
    console.log(window.location.origin);

    window.location.href = red;
  };  

  return (
    <div
      style={{
        top: 0,
        left: 0,
        position: "absolute",
        height: "100%",
        width: "100%",
      }}
      onKeyPress={handleEnterPress}
    >
      <main className={styles.main}>
        <Paper className={styles.paper}>
          <img
            src={themeColor === "light" ? colorLogo : whiteLogo}
            style={{ height: 100, margin: 12 }}
            alt="Logo acción circular"
          />
          <TextField
            value={formData.email}
            error={loginStatus.error}
            name="email"
            label="Correo"
            // autoComplete="email"
            // autoFocus
            variant="outlined"
            fullWidth
            margin="dense"
            onChange={updateData}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            style={{ marginTop: 16 }}
            value={formData.password}
            error={loginStatus.error}
            name="password"
            type="password"
            label="Contraseña"
            variant="outlined"
            autoComplete="current-password"
            onChange={updateData}
            margin="dense"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            fullWidth
            color="primary"
            style={
              loginStatus.step === "DONE"
                ? {
                    marginTop: 14,
                    background:
                      "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
                    color: "#fff",
                  }
                : {
                    marginTop: 14,
                  }
            }
            variant={loginStatus.step === "DONE" ? "contained" : "outlined"}
            onClick={() =>
              login({ email: formData.email, password: formData.password })
            }
          >
            Entrar
          </Button>
          <br></br>
          {themeColor === "light" ? 
            <p style={{ color: "#303f9f" }}>
              <u onClick={goReset}>Recuperar contraseña</u>
            </p> 
          : 
          <p style={{ color: "#FFFFFF" }}>
            <u onClick={goReset}>Recuperar contraseña</u>
          </p>
        }
          
        </Paper>
      </main>
    </div>
  );
}
