import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
import RoomIcon from "@material-ui/icons/Room";
import MapIcon from "@material-ui/icons/Map";
import { format } from "rut.js";

import communesData from "../../../../../resources/communes";
import UploadImage from "./UploadImagen";

const useStyles = makeStyles({
  root: {
    border: "2px #45cafc",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `#303f9f !important`,
    },
  },
  notchedOutline: {
    borderWidth: "2px",
    borderColor: "#303f9f !important",
  },
});

const TextFieldStyled = ({
  value,
  name,
  error,
  label,
  onChange,
  adornment,
  edit,
  disabled,
}) => {
  const styles = useStyles();
  return (
    <TextField
      margin="dense"
      variant="outlined"
      fullWidth
      name={name}
      label={label}
      value={value}
      error={!value && error}
      disabled={disabled}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={
        edit && !error
          ? {
            classes: {
              root: styles.cssOutlinedInput,
              notchedOutline: styles.notchedOutline,
              focused: styles.focused,
            },
            readOnly: false,
            startAdornment: (
              <InputAdornment position="start">{adornment}</InputAdornment>
            ),
          }
          : {
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">{adornment}</InputAdornment>
            ),
          }
      }
    />
  );
};

export default function GeneralDataForm({
  error,
  formData,
  setFormData,
  edit,
  communes,
}) {
  const updateData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <hr />
        <Typography component="h2" variant="h5">
          Información general
        </Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextFieldStyled
          value={formData.brand}
          name="brand"
          label="Nombre empresa"
          error={error}
          disabled={true}
          adornment={<StoreIcon />}
          onChange={updateData}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextFieldStyled
          value={format(formData.rut)}
          name="rut"
          label="Rut empresa"
          error={error}
          disabled={true}
          adornment={<StoreIcon />}
          onChange={updateData}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextFieldStyled
          value={formData.address}
          name="address"
          label="Dirección empresa"
          error={error}
          disabled={true}
          onChange={updateData}
          adornment={<RoomIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          label="Comuna"
          name="commune"
          value={formData.commune}
          type="text"
          disabled
          select
          onChange={(e) => updateData(e)}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: !edit,
            startAdornment: (
              <InputAdornment>
                <MapIcon />
              </InputAdornment>
            ),
          }}
          error={!formData.commune && error}
        >
          <MenuItem value="">
            <em>Ninguna</em>
          </MenuItem>
          {communes.map((commune, idx) => (
            <MenuItem
              key={`op${idx}-${commune.code}`}
              value={commune.code}>
              {commune.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <hr></hr>
      </Grid>
      <Grid item xs={12} sm={3}>
        {edit && (
          <UploadImage
            name="companyLogo"
            setFormData={setFormData}
            formData={formData}
          />
        )}
      </Grid>
    </Grid>
  );
}
