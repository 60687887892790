import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  Paper,
} from "@material-ui/core";
import {
  Home as HomeIcon,
  LocalShipping as RemovalsIcon,
  Assignment as ReportsIcon,
} from "@material-ui/icons";
import SettingsIcon from "@material-ui/icons/Settings";
import StoreIcon from "@material-ui/icons/Store";
import SyncIcon from "@material-ui/icons/Sync";

import { useAppContext } from "../../context/AppContext";

import logo from "../../resources/images/logo.png";

const useStyles = makeStyles(() => ({
  mobileList: {
    width: "250px",
    height: "100%",
  },
  toolbarLogo: {
    height: "60px",
    margin: "10px",
  },
}));

const items = {
  customer: [
    {
      text: "Home",
      to: "/",
      icon: <HomeIcon />,
    },
    {
      text: "Sucursales",
      to: "/locals",
      icon: <StoreIcon />,
    },
    {
      text: "Retiros",
      to: "/removals",
      icon: <RemovalsIcon />,
    },
    {
      text: "Sensores",
      to: "/sensors",
      icon: <SyncIcon />,
    },
    {
      text: "Reportes",
      to: "/reports",
      icon: <ReportsIcon />,
    },
    {
      text: "Reportes Excel",
      to: "/reportsExcel",
      icon: <ReportsIcon />,
    },
    {
      text: "Aprendizaje",
      to: "/learning",
      icon: <ReportsIcon />,
    },
    {
      text: "Perfil",
      to: "/profile",
      icon: <SettingsIcon />,
    },
  ],
  local: [
    {
      text: "Home",
      to: "/",
      icon: <HomeIcon />,
    },
    {
      text: "Retiros",
      to: "/removals",
      icon: <RemovalsIcon />,
    },
    {
      text: "Sensores",
      to: "/sensors",
      icon: <SyncIcon />,
    },
    {
      text: "ReportesExcel",
      to: "/reportsExcel",
      icon: <ReportsIcon />,
    },
    {
      text: "Perfil",
      to: "/profile",
      icon: <SettingsIcon />,
    },
  ],
  transporter: [
    {
      text: "Home",
      to: "/",
      icon: <HomeIcon />,
    },
    {
      text: "Retiros",
      to: "/removals",
      icon: <RemovalsIcon />,
    },
    {
      text: "Perfil",
      to: "/profile",
      icon: <SettingsIcon />,
    },
  ]
};

export default function SideNavigation({ mobile, closeSideNav }) {
  const { userType } = useAppContext();

  const classes = useStyles();
  const location = useLocation();

  if (mobile) {
    return (
      <Paper
        className={classes.mobileList}
        onClick={closeSideNav}
        onKeyDown={closeSideNav}
      >
        <Grid container item xs={12} justify="center">
          <img
            className={classes.toolbarLogo}
            src={logo}
            alt="Acción Circular"
          />
        </Grid>
        <Divider />
        <List>
          {items[userType.toLowerCase()].map((element) => (
            <NavLink
              exact={true}
              to={element.to}
              key={"menu_item_" + element.text}
            >
              <ListItem>
                <ListItemIcon>{element.icon}</ListItemIcon>
                <ListItemText>{element.text}</ListItemText>
              </ListItem>
            </NavLink>
          ))}
        </List>
      </Paper>
    );
  } else {
    return (
      <div
        className="sidebar-fixed position-fixed"
        style={{
          paddingTop: "2%",
          marginLeft: 12,
        }}
      >
        <Paper>
          <List>
            {items[userType.toLowerCase()].map((element) => (
              <NavLink
                exact={true}
                to={element.to}
                key={"menu_item_" + element.text}
                strict
                style={{
                  textDecoration: "none",
                }}
              >
                <ListItem selected={location.pathname === element.to}>
                  <ListItemIcon>{element.icon}</ListItemIcon>
                  <ListItemText>{element.text}</ListItemText>
                </ListItem>
              </NavLink>
            ))}
          </List>
        </Paper>
      </div>
    );
  }
}
