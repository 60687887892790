import React from "react";
import {
  Grid,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";

import Conditions from "./Conditions";

export default function PriceAndTerms({ formData, setFormData }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        {/* 
        <Typography variant="h6">
          Rango estimado de precio del retiro
        </Typography>
        <Typography variant="h5">$20.000 ~ $27.000</Typography>
        */}
        <Typography variant="h6">
          Se aplicarán cargos extra para este tipo de retiros.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <hr />
        <Typography variant="h6">Términos y condiciones</Typography>
        <Typography variant="p">
          Este retiro esta sujeto a los siguientes términos y condiciones
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <hr></hr>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Conditions/>
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={formData.check}
                  onChange={(e) =>
                    setFormData({ ...formData, check: e.target.checked })
                  }
                  color="primary"
                />
              }
              label="Acepto los términos y condiciones propuestos"
            />
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

PriceAndTerms.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};
