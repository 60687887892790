import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function Chart({ data }) {
  const isMobile = false;
  const [payload, setPayload] = React.useState([]);

  React.useEffect(() => {
    let aux = [];
    data.reverse().map((read) => {
      const date = new Date(read.createdAt)
      aux.push({
        date: new Intl.DateTimeFormat("en-GB").format(date),
        Llenado: read.fill,
      });
      return null;
    });
    setPayload(aux);
  }, [data]);
  return (
    <ResponsiveContainer width="100%" aspect={isMobile ? 4.0 / 4.0 : 4.0 / 1}>
      <AreaChart data={payload}>
        <XAxis dataKey="date"> <Label value="Fecha" offset={-2}  position="insideBottom" /></XAxis>
        <YAxis ><Label value="Llenado" angle="-90" position="insideLeft" /></YAxis>
        <Tooltip formatter={(Llenado) => Llenado + " %"} />
        <defs>
          <linearGradient id="Llenado" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#5499C7" stopOpacity={0.6} />
            <stop offset="95%" stopColor="#5499C7" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
            type="monotone"
            dataKey="Llenado"
            stroke="#5499C7"
            fillOpacity={1}
            fill='url(#Llenado)'
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
