import React, {useCallback, useState} from "react";
import { Grid, Button, makeStyles } from "@material-ui/core";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import swal from 'sweetalert';
import PropTypes from "prop-types";
import actionsRemovals from "../../../../redux/actions/removals";
import {useDispatch} from "react-redux";
import { useAppContext } from "../../../../context/AppContext";


const styles = makeStyles(() => ({
  buttonRequest: {
    background:
      "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
    color: "#fff",
  },
  warningSensorStateAccept: {
    background:"#303F9F",
    color: "#fff",
    padding:"1.5%",

  },
  disable: {
    padding:"1.5%",

  },
  warningSensorStateRefuse: {
    background: "#FBC02D",
    color: "#fff",
    padding:"1.5%",
    float: "right",
  },
  ButtonComplete: {},
  ButtonState: {
    // background:
    //   "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
    // color: "#fff",
  },
}));

function ramdom() {
  console.log("hola");


}

export default function Buttons({
  removal,
  setRemoval,
  viewState,
  status,
  setViewState,
  setViewRemoval,
  setCoordinateRemoval,

}) {
  const viewRemoval = () => {
    setRemoval(removal);
    setViewRemoval(true);
  };
  const coordinate = () => {
    setRemoval(removal);
    setCoordinateRemoval(true);
  };
  const dispatch = useDispatch();

  return (
    <Grid item xs={12}>
      {status === 1 || status === 2 || status === 3 ? (
        <ButtonState
          viewState={viewState}
          setViewState={setViewState}
          removal={removal}
        />
      ) : status === 4 ? (
        <ButtonComplete viewRemoval={viewRemoval} removal={removal} />
      ) : status === 7 || status === 8 ? (
          <ButtonRequestSensor removal={removal} coordinate={coordinate} />
      ) : (
        <ButtonRequest removal={removal} coordinate={coordinate} />
      )}
    </Grid>
  );
}

function ButtonState({ setViewState, viewState }) {
  const classes = styles();

  return (
    <Button
      fullWidth
      color="primary"
      variant="text"
      onClick={() => setViewState(!viewState)}
      startIcon={
        viewState ? <VisibilityOffIcon /> : <RemoveRedEyeOutlinedIcon />
      }
      className={classes.ButtonState}
    >
      estado
    </Button>
  );
}

function ButtonComplete({ viewRemoval, removal }) {
  
  const { userType, entityID } = useAppContext();

  return (
    userType === "TRANSPORTER" ? 
    <Button
      fullWidth
      color="primary"
      variant="outlined"
      onClick={() => viewRemoval()}
      startIcon={<RemoveRedEyeOutlinedIcon />}
      style={{ color: "#00C851", borderColor: "#00c851" }}
    >
      ver
    </Button>
    :
    <Button
      fullWidth
      color="primary"
      variant="outlined"
      disabled={!removal.rating}
      onClick={() => viewRemoval()}
      startIcon={<RemoveRedEyeOutlinedIcon />}
      style={removal.rating ? { color: "#00C851", borderColor: "#00c851" } : {}}
    >
      ver
    </Button>
  );
}

function ButtonRequest({ removal, coordinate }) {
  const classes = styles();
  const disabled = removal.status === 6;
  return (
    <Button
      fullWidth
      disabled={disabled}
      variant={disabled ? "text" : "contained"}
      onClick={() => coordinate()}
      startIcon={<CheckCircleOutlinedIcon />}
      className={!disabled ? classes.buttonRequest : {}}
    >
      solicitar
    </Button>
  );
}

function ButtonRequestSensor({ removal }) {
  const dispatch = useDispatch();
  const classes = styles();
  const disabled = removal.status === 8;
  return (<Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
              disabled={disabled}
              variant={disabled ? "text" : "contained"}
              onClick={() => swal({
                title:"Ha aceptado el retiro",
                buttons: {Aceptado: {value: "funciona"}},
              }).then(value => {
                if (value)
                  dispatch(actionsRemovals.requestRemovalBySensorState(removal))
              })}
              className={!disabled ? classes.warningSensorStateAccept : classes.disable}
          >
            Aceptar Retiro
          </Button>
          <Button
              disabled={disabled}
              variant={disabled ? "text" : "contained"}
              onClick={() => swal({
                title: "Descartar retiro sensor",
                text: "¿Está seguro que desea deshabilitar el retiro?",
                icon: "warning",
                buttons: {Descartar: {value: "funciona"}},
                dangerMode: true,
              })
                  .then((value) => {
                    if (value) {
                      dispatch(actionsRemovals.removalInactive(removal._id));
                      swal("Retiro deshabilitado", {
                        icon: "success",
                      });
                    }
                  })}
              className={!disabled ? classes.warningSensorStateRefuse : {}}
          >
            Descartar Retiro
          </Button>
        </Grid>
      </Grid>
  );
}
ButtonState.propTypes = {
  viewState: PropTypes.object.isRequired,
  setViewState: PropTypes.func.isRequired,
};

ButtonComplete.propTypes = {
  removal: PropTypes.object.isRequired,
  viewRemoval: PropTypes.func.isRequired,
};

ButtonRequest.propTypes = {
  removal: PropTypes.object.isRequired,
  coordinate: PropTypes.func.isRequired,
};

ButtonRequestSensor.propTypes = {
  removal: PropTypes.object.isRequired,
  coordinate: PropTypes.func.isRequired,
};

Buttons.propTypes = {
  removal: PropTypes.object.isRequired,
  setRemoval: PropTypes.func.isRequired,
  viewState: PropTypes.bool.isRequired,
  status: PropTypes.number.isRequired,
  setViewState: PropTypes.func.isRequired,
  setViewRemoval: PropTypes.func.isRequired,
  setCoordinateRemoval: PropTypes.func.isRequired,
};

