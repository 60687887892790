import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField,
  Box,
  Typography,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import AssessmentIcon from "@material-ui/icons/Assessment";

export default function ReportCard({ report }) {
  return (
    <Box boxShadow={3}>
      <Card>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography component="h2" variant="h6">
                Reporte disponible
              </Typography>
            </Grid>
            <Grid item>
              <AssessmentIcon style={{ color: "#00C851" }} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <hr color="#00C851" style={{ height: 2 }}></hr>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                value={report.dateRange}
                variant="outlined"
                label="Período"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                fullWidth
                variant="outlined"
                onClick={() => window.open(report.url)}
                startIcon={<GetAppIcon />}
                style={{ color: "#00C851", borderColor: "#00C851" }}
              >
                Descargar
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
