import React from "react";
import { useAppContext } from "./AppContext";
import IO from "socket.io-client";

const socket = IO(process.env.REACT_APP_API_URL);
const SocketContext = React.createContext();

export function SocketContextProvider(props) {
  const { userType, entityID } = useAppContext();
  const [action, setAction] = React.useState("");
  const [message, setMessage] = React.useState("");

  eventListener({ entityID, setAction, setMessage });

  const value = React.useMemo(() => {
    const sendChatMessage = (data) => {
      eventEmitter({ action: "NEW_CHAT_MESSAGE", entityID, userType, data });
    };
    return { action, message, sendChatMessage };
  }, [action, message, entityID, userType]);

  return <SocketContext.Provider value={value} {...props} />;
}

export function useSocketContext() {
  const context = React.useContext(SocketContext);
  if (!context) {
    throw new Error("NO SUSCRITO AL CONTEXTO");
  }
  return context;
}

const eventListener = ({ entityID, setAction, setMessage }) => {
  socket.on("INCOMING_MESSAGE::" + entityID, (payload) => {
    setMessage(payload);
  });

  socket.on("NEW_ACTION::" + entityID, (payload) => {
    setAction(payload);
  });
};

const eventEmitter = (event) => {
  socket.emit(event.action, { ...event });
};
