import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

const fetchLocals = () => {
    return async (dispatch) => {
      try {
        const response = await axios.get(apiUrl + "/webApp/local");
        debugger;
        dispatch({ type: "LOCAL_FETCH", payload: response.data });
        return true;
      } catch (e) {
        return false;
      }
    };
  };

  const updateLocals = (formData) => {
    return async (dispatch) => {
      try {
        debugger;
        const response = await axios.post(apiUrl + "/webApp/local/update", formData, {headers: {
          'Content-Type': 'multipart/form-data'
          }});
          console.log(response);
        return response.data;
      } catch (e) {
        return false;
      }
    };
  };

  export default {
    fetchLocals,
    updateLocals
  };