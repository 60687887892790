import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

const fetchLearning = () => {
    return async (dispatch) => {
      try {
        const response = await axios.get(apiUrl + "/webApp/learning");
        
        dispatch({ type: "LEARNING_FETCH", payload: response.data });
        return true;
      } catch (e) {
        return false;
      }
    };
  };

  const viewDisplay = (formData) => {
    return async (dispatch) => {
      try {
        
        const response = await axios.post(apiUrl + "/admintool/learning/display", formData, {headers: {
          'Content-Type': 'multipart/form-data'
          }});
          var x = response.data;
          console.log(x)

        return x;
      } catch (e) {
        return false;
      }
    };
  };

  export default {
    fetchLearning,
    viewDisplay
  };