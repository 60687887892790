import React from "react";
import Axios from "../../api/Axios";
import { TextField, Button, Paper, makeStyles } from "@material-ui/core";
import colorLogo from "../../resources/images/logo-color.png";
import whiteLogo from "../../resources/images/logo.png";

import { useAppContext } from "../../context/AppContext";
import { TrackChangesOutlined } from "@material-ui/icons";
const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: spacing(3),
    marginRight: spacing(3),
    [breakpoints.up(400 + spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
    borderRadius: "6px",
  },
  paper: {
    marginTop: spacing(18),
    marginBottom: spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${spacing(2)}px ${spacing(3)}px ${spacing(3)}px`,
    borderRadius: "8px",
  },
}));

window.onload = function() {
  // alert("orlaff");
  var wnd = window.location.pathname;

  if(wnd == '/resetpass'){
    var red = window.location.search;

    if(red.length > 0){
      var tkn = red.replace("?", "");
      console.log(tkn);
      document.getElementById("token").value = tkn;
    } else {
      var red1 = window.location.origin + "/login";
      window.location.href = red1;
    }
  }

};

async function goback() {

  // var red = window.location.origin + "/login";
  // window.location.href = red;

}

async function changepass() {

  // try {
    var password = document.getElementById("password").value;
    var pass2 = document.getElementById("passwordR").value;
    var token = document.getElementById("token").value;
    var red = window.location.origin + "/login";

    console.log(password);
    console.log(pass2);
    console.log(token);

    if(password.length > 0 || pass2.length > 0 ){
      if(password == pass2){
        let response = await Axios.post("/auth/reset_password", { token, password });
        console.log(response);
        console.log(response.data.success);

        if(response.data.success == 1){
          alert("Contraseña cambiada con exito.");
          window.location.href = red;
        } else {
          alert(response.data.message);
        }

      } else {
        alert("Las contraseñas deben ser iguales");
      }
    } else {
      alert("Todos los campos son obligatorios");
    }


  //   // let response = await Axios.post("/auth/login", { email, password });
    
  //   if(email != '' || emailR != ''){
  //     if(email == emailR){
  //     let response = await Axios.post("/auth/recover", { email });
  //     console.log(response);
  //       if(response.status == 200){
  //         alert("Se ha enviado las instrucciones a tu correo.");
  //         var red = window.location.origin + "/login";
  //         window.location.href = red;
  //       } else {
  //         alert("Hubo un problema al recuperar tu contraseña.");
  //       }      
  //     } else {
  //       alert("Los correos ingresados son distintos.");
  //     }
  //   } else {
  //     alert("Los campos son obligatorios.");
  //   }

  // } catch (error) {
  //   console.log(error);
  //   alert("Hubo un problema al recuperar tu contraseña.");
  // }

}

export default function Login({}) {
  const styles = useStyles();

  const { loginStatus, themeColor } = useAppContext();
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });

  // const updateData = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const handleEnterPress = (event) => {
    // if (event.key === "Enter") {
    //   login({ email: formData.email, password: formData.password });
    // }
  };

  return (
    <div
      style={{
        top: 0,
        left: 0,
        position: "absolute",
        height: "100%",
        width: "100%",
      }}
      onKeyPress={handleEnterPress}
    >
      <main className={styles.main}>
        <Paper className={styles.paper}>
          <img
            src={themeColor === "light" ? colorLogo : whiteLogo}
            style={{ height: 100, margin: 12 }}
            alt="Logo acción circular"
          />
          <TextField
            type="hidden"
            name="token"
            id="token"
          />
          <TextField
            name="password"
            id="password"
            label="Contraseña"
            type="password"
            // autoComplete="email"
            // autoFocus
            variant="outlined"
            fullWidth
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            name="passwordR"
            id="passwordR"
            label="Repite Contraseña"
            type="password"
            // autoComplete="email"
            // autoFocus
            variant="outlined"
            fullWidth
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            fullWidth
            color="primary"
            style={
                  {                    
                    background:
                      "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
                    color: "#fff",
                    marginTop: 14,
                  }
            }
            onClick={changepass}
          >
            Cambiar Contraseña
          </Button>
        </Paper>
      </main>
    </div>
  );
}
