import React from "react";

import Container from "./Container";
import { useSensorsContext } from "../../../../context/SensorsContext";

export default function Index() {
  const { getSensorsFilled } = useSensorsContext();
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    getSensorsFilled().then((response) => {
      setData(response);
    });
  }, [getSensorsFilled]);

  return (
    <div>
      {data.map((filled, i) => (
        <Container
          filled={filled.data.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.lastReadDate) - new Date(a.lastReadDate);
          })}
          local={filled.local}
          key={"container_" + i}
        />
      ))}
    </div>
  );
}
