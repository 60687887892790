import React from "react";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";

import water from "../../../../resources/images/gota agua.png";
import industry from "../../../../resources/images/fabrica.png";
import energy from "../../../../resources/images/rayo.png";
import tree from "../../../../resources/images/arbol.png";
import balance from "../../../../resources/images/balanza.png";
import gota from "../../../../resources/images/gota.png";
import EcoCard from "./EcoCard";
import { useDashboardContext } from "../../../../context/DashboardContext";

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const data = [
  {
    materialID: "PETROL",
    image: {
      light: gota,
      dark: gota,
    },
    alt: "litros de petróleo",
    unity: "L",
    subtitle: "de petróleo",
    value: -1,
  },
  {
    materialID: "ENERGY",
    image: {
      light: energy,
      dark: energy,
    },
    alt: "kWatt de energía",
    unity: "kWatt",
    subtitle: "de energía",
    value: -1,
  },
  {
    materialID: "CO2",
    image: {
      light: industry,
      dark: industry,
    },
    alt: "kilos de CO2",
    unity: "Kg",
    subtitle: "de CO2",
    value: -1,
  },
  {
    materialID: "WATER",
    image: {
      light: water,
      dark: water,
    },
    alt: "litros de agua",
    unity: "L",
    subtitle: "de agua",
    value: -1,
  },
  {
    materialID: "TREE",
    image: {
      light: tree,
      dark: tree,
    },
    alt: "árboles",
    unity: "Un",
    subtitle: "de árboles",
    value: -1,
  },
  {
    materialID: "MATERIAL",
    image: {
      light: balance,
      dark: balance,
    },
    alt: "kilos de material",
    unity: "Kg",
    subtitle: "de material",
    value: -1,
  },
];
export default function EcoEq({ dashboardData }) {
  const [cards, setCards] = React.useState(data);
  const { selectDate, dateInit } = useDashboardContext();

  React.useEffect(() => {
    if (dashboardData.ecoeq) {
      setCards(
        data.map((element) => {
          if (element.materialID === "MATERIAL") {
            return {
              ...element,
              value: Math.round(
                dashboardData.totalMaterials?.reduce(
                  (ac, material) => ac + material.quantity * 1,
                  0
                )
              ),
            };
          }
          return {
            ...element,
            value: Math.round(
              Math.round(
                dashboardData.ecoeq?.filter(
                  (e) => e.ID === element.materialID
                )[0].q
              ),
              0
            ),
          };
        })
      );
    }
  }, [dashboardData]);

  return (
    <Card>
      <Typography variant="h5" align="center" color="textSecondary">
        {selectDate === 0
          ? "Eco equivalencias recuperadas durante " +
            months[dateInit.getMonth()]
          : "Eco equivalencias recuperadas acumuladas"}
      </Typography>
      <CardContent>
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          {cards.map((card, i) => (
            <EcoCard data={card} key={i} />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
