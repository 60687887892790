import React,{useState} from "react";
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  Button,
  useTheme,
  TextField,
  Box,
  useMediaQuery,
  Typography,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import { useSelector, useDispatch } from "react-redux";
import localsActions from "../../../redux/actions/locals";


export default function ReportCard({ local }) {
  const dispatch = useDispatch();
  const [view, setView] = useState(false);
  const [localEdit, setLocalEdit] = useState({contactName: "", phone: "", _id:""});
  const [error, setError] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [edit, setEdit] = React.useState(false);

  async function handleEdit(e) {
    e.preventDefault();
    debugger;
    const formData = new FormData();
    formData.append("contactName", localEdit.contactName);
    formData.append("phone", localEdit.phone);
    formData.append("_id", localEdit.id);

      try {
      const response = await dispatch(localsActions.updateLocals(formData));
      setIsUploaded(true);
      setIsUploading(false);
      window.location.reload();
    } catch (err) {
      setError(err.message);
      setIsUploading(false);
      handleClose();
    }
}

function handleClickEdit(){
      
  setLocalEdit({
    contactName: local.contactName,
    phone: local.phone,
    id: local._id
  })
  setView(true);
}

  const handleChange = e => {
    const {name, value} = e.target
    setLocalEdit({
        ...localEdit,
        [name]: value,
    })
  } 

  const handleClose = () => {
    setView(false);
    setEdit(false);
  };
  console.log(local)

  return (
    <>
    <Box boxShadow={3}>
      <Card>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h6">{local.name}</Typography>
            </Grid>
            <Grid item>
              <StoreIcon style={{ color: "#00C851" }} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <hr color="#00C851" style={{ height: 2 }}></hr>
            </Grid>
            <Grid item xs={12}>
              <label>Nombre contacto: {local.contactName}</label>
              <label>Teléfono contacto: {"+" + local.phone}</label>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                value={local.suscriptionID && local.suscriptionID.name}
                variant="outlined"
                label="Suscripción"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Button
                color="primary"
                fullWidth
                variant="outlined"
                startIcon={<RemoveRedEyeOutlinedIcon />}
                style={{ color: "#00C851", borderColor: "#00C851" }}
              >
                Ver
              </Button>
            </Grid> */}
            <Button onClick={() => handleClickEdit()}>Editar</Button>
          </Grid>
        </CardContent>
      </Card>
      
    </Box>
    <Dialog
      open={view}
      maxWidth="sm"
      fullWidth
      scroll={"body"}
    >

    <DialogTitle id="form-dialog-title">Modificar</DialogTitle>
      <DialogContent>
        <TextField 
          name="contactName"
          label="Nombre contacto"
            variant="outlined"
            fullWidth
            margin="dense"
            value={localEdit.contactName}
            onChange={handleChange}
        />
        <TextField 
          name="phone"
          label="Telefono contacto"
            variant="outlined"
            fullWidth
            margin="dense"
            value={localEdit.phone}
            onChange={handleChange}
        />
      </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancelar
          </Button>
          <Button color="success" onClick={(e) => handleEdit(e)}>
            Modificar
          </Button>
        </DialogActions>
    </Dialog>
    </>
  );
}
