import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
  MenuItem,
} from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import Jimp from "jimp";
import PropTypes from "prop-types";
import removalActions from "../../../../redux/actions/removals";
import { useDispatch } from "react-redux";
import { useAppContext } from "../../../../context/AppContext";

export default function ModalCoordinate({ setCoordinateRemoval, localID }) {
  const { userType, entityID } = useAppContext();

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [formData, setFormData] = useState({
    datetimeRemoval: "0",
    window: "A",
    description: "",
    image: "",
  });
  const [error, setError] = React.useState(false);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  const handleSetImages = (event) => {
    Array.from(event.target.files).forEach((file) => {
      setTimeout(() => {
        Jimp.read(URL.createObjectURL(file))
          .then((photo) => {
            photo
              .contain(800, 800)
              .quality(100)
              .getBase64Async(Jimp.MIME_JPEG)
              .then((res) => {
                setFormData({ ...formData, image: res });
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => {
            console.error(err);
          });
      }, 1000);
    });
  };

  const success = async () => {
    if (formData.datetimeRemoval !== "0") {
      let resp = await dispatch(
        removalActions.requestRemoval({ formData, userType, entityID, localID })
      );
      setCoordinateRemoval(false);
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => setCoordinateRemoval(false)}
      maxWidth="sm"
      fullWidth
      scroll="body"
      fullScreen={isMobile}
    >
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography component="h2" variant="h5">
              Solicitar retiro
            </Typography>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={() => setCoordinateRemoval(false)}>
              <ClearOutlinedIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <hr></hr>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Dates
              formData={formData}
              setFormData={setFormData}
              error={error}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <input
              accept="image/*"
              style={{ display: "none", width: "100%" }}
              id="raised-button-file-2"
              type="file"
              onChange={(event) => handleSetImages(event)}
            />
            <label htmlFor="raised-button-file-2" style={{ width: "100%" }}>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  marginTop: 8,
                  height: 40,
                }}
                fullWidth
                startIcon={<CloudUploadOutlinedIcon />}
                component="span"
              >
                subir foto
              </Button>
            </label>
          </Grid>
          <Grid item xs={12}>
            {formData.image && (
              <img src={formData.image} style={{ width: 200 }} alt={"Imagen"} />
            )}{" "}
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="Descripción del retiro"
              fullWidth
              rows={4}
              placeholder="Escribe aquí toda la información que consideres relevante para tu retiro. Qué es lo que se retira, sugerencias especiales, acceso al lugar, etc..."
              multiline
              InputLabelProps={{ shrink: true }}
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{
            background:
              "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
            margin: 12,
            color: "#fff",
          }}
          startIcon={<CheckCircleOutlinedIcon />}
          onClick={() => success()}
        >
          Solicitar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Dates({ error, setFormData, formData }) {
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const months = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    let aux = new Date();
    let temp = [];

    aux.setDate(aux.getDate() + 2);
    aux.setHours(0);
    aux.setMinutes(0);
    aux.setSeconds(1);

    for (let i = 0; i < 14; i++) {
      if (aux.getDay() !== 0) {
        temp.push({
          value: new Date(aux),
          option:
            days[aux.getDay()] +
            " " +
            aux.getDate() +
            " de " +
            months[aux.getMonth()] +
            " " +
            aux.getFullYear(),
        });
      }
      aux.setDate(aux.getDate() + 1);
    }
    setOptions(temp);
  }, []);

  const handleChange = (e) => {
    let aux = e.target.value;
    let temp = "";
    if (aux !== "0") {
      temp =
        days[aux.getDay()] +
        " " +
        aux.getDate() +
        " de " +
        months[aux.getMonth()] +
        " " +
        aux.getFullYear();
    }

    setFormData({ ...formData, datetimeRemoval: aux, dateString: temp });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          select
          label="Fecha"
          InputLabelProps={{ shrink: true }}
          value={formData.datetimeRemoval}
          onChange={handleChange}
          error={error && formData.datetimeRemoval === "0"}
          helperText={
            error && formData.datetimeRemoval === "0" && "Selecciona una fecha"
          }
        >
          <MenuItem value={"0"}>
            <em>Selecciona una fecha</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value + "_option"} value={option.value}>
              {option.option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          select
          label="Horario"
          InputLabelProps={{ shrink: true }}
          value={formData.window}
          onChange={(e) => setFormData({ ...formData, window: e.target.value })}
        >
          <MenuItem value="A">A (9:00 a 13:00)</MenuItem>
          <MenuItem value="B">B (14:00 a 19:00)</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
}

Dates.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

ModalCoordinate.propTypes = {
  removal: PropTypes.object.isRequired,
  setCoordinateRemoval: PropTypes.func.isRequired,
  localID: PropTypes.string,
};
