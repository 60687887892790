import React from "react";
import PropTypes from "prop-types";
import { LinearProgress, Typography, Box, makeStyles } from "@material-ui/core";

export default function LinearProgressBattery(props) {
  const primaryColor =
      /* props.value > 30
         ? "#303f9f"
         : props.value <= 30 && props.value > 10
         ? "#ffbb33"
         : "#ff4444"; // VALIDACION DESARROLLO ANTERIOR*/

      //Validación nueva
      props.value >= 61
      ? "#309f42"
      : props.value <= 60 && props.value >= 31
      ? "#ffbb33"
      : "#ff4444";

  const progressStyle = makeStyles((_theme) => ({
    barColorPrimary: { backgroundColor: primaryColor },
  }));

  const classes = progressStyle();
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          {...props}
          classes={{
            barColorPrimary: classes.barColorPrimary,
          }}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressBattery.propTypes = {
  value: PropTypes.number.isRequired,
};
