import React from "react";
import {
  Grid,
  Card,
  useMediaQuery,
  useTheme,
  Typography,
} from "@material-ui/core";
import { useDashboardContext } from "../../../context/DashboardContext";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Area,
  Cell,
} from "recharts";

import { parseFlag } from "../../../resources/residuesTypes";
import { parseFlagEcoeq } from "../../../resources/ecoeqTypes";

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const colorsDataPieMaterials = [
  "#45cafc",
  "#D5DBDB",
  "#FFBB28",
  "#0088FE",
  "#85929E",
  "#D7BDE2",
  "#00C49F",
  "#A04000",
  "#E5E8E8",
  "",
];

const colorsDataPieEcoeq = [
  "#00C49F",
  "#0088FE",
  "#2c382a",
  "#FFBB28",
  "#D5DBDB",
];

export default function Charts() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { dashboardData, selectDate, dateInit } = useDashboardContext();

  const [dataTotalMaterials, setDataTotalMaterials] = React.useState([]);
  const [dataPieChartMaterials, setDataPieChartMaterials] = React.useState([]);
  const [dataPieEcoec, setDataPieEcoeq] = React.useState([]);

  React.useEffect(() => {
    if (dashboardData.totalMaterials) {
      let totalMaterials = dashboardData.totalMaterials;
      let totalMaterialsPrevius = dashboardData.totalMaterialsPrevius;
		//console.log(totalMaterials);
		//console.log(totalMaterialsPrevius);
      setDataTotalMaterials(
        totalMaterials.map((element, i) => {
          /*if (totalMaterialsPrevius.length) {
            return {
              name: parseFlag(element._id),
              [months[dateInit.getMonth()]]: element.quantity,
              [months[
                dateInit.getMonth() - 1 < 0 ? 11 : dateInit.getMonth() - 1
              ]]: totalMaterialsPrevius[i]?.quantity,
              Promedio:
                (element.quantity + totalMaterialsPrevius[i]?.quantity) / 2,
            };
			
          } else {*/
            return {
              name: parseFlag(element._id),
              Acumulado: element.quantity,
            };
          /*}*/
        })
      );
	  console.log("despues" + JSON.stringify(totalMaterials));
      const total = totalMaterials.reduce(
        (sum, cur) => (sum += cur.quantity),
        0
      );
      setDataPieChartMaterials(
        totalMaterials.map((element) => {
          return {
            name: parseFlag(element._id),
            value: Math.round((element.quantity / total) * 100),
          };
        })
      );
    }
    if (dashboardData.ecoeq) {
      const totalEcoeq = dashboardData.ecoeq.reduce(
        (sum, cur) => (sum += cur.q),
        0
      );
      setDataPieEcoeq(
        dashboardData.ecoeq.map((element) => {
          return {
            name: parseFlagEcoeq(element.ID),
            value: Math.round((element.q * 100) / totalEcoeq),
          };
        })
      );
    }
  }, [dashboardData, selectDate, dateInit]);
	 console.log(dataTotalMaterials);
  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center" color="textSecondary">
            Material reciclado
          </Typography>
          <ResponsiveContainer
            width="100%"
            aspect={isMobile ? 4.0 / 4.0 : 4.0 / 1.5}
          >
            <ComposedChart
              width={900}
              data={dataTotalMaterials}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 80,
              }}
            >
              <XAxis dataKey="name" angle={-45} textAnchor="end" />
              {!isMobile && (
                <YAxis
                  label={{
                    value: "Kg de material",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
              )}
              <Legend
                verticalAlign="top"
                wrapperStyle={{ lineHeight: "40px" }}
              />
              <Tooltip />
			  <Bar dataKey={"Acumulado"} fill="#0088FE" unit=" Kg" /> 
              /*{!selectDate && (
                <Bar
                  dataKey={months[dateInit.getMonth()]}
                  fill="#303f9f"
                  unit=" Kg"
                />
              )}
              {!selectDate && (
                <Bar
                  dataKey={
                    months[
                      dateInit.getMonth() - 1 < 0 ? 11 : dateInit.getMonth() - 1
                    ]
                  }
                  fill="#45cafc"
                  unit=" Kg"
                />
              )}
              {selectDate === 1 && (
                <Bar dataKey={"Acumulado"} fill="#0088FE" unit=" Kg" />
              )}
              {!selectDate && (
                <Area
                  type="monotone"
                  dataKey={"Promedio"}
                  fill="#EAEDED"
                  stroke="#8884d8"
                  unit=" Kg"
                />
              )}*/
            </ComposedChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" align="center" color="textSecondary">
            {selectDate === 0
              ? "Relación porcentual por tipo de material " +
                months[dateInit.getMonth()]
              : "Relación porcentual por tipo de material acumulado"}
          </Typography>
          <ResponsiveContainer width="100%" aspect={4.0 / 4.0}>
            <PieChart width={450} height={400}>
              <Pie
                data={dataPieChartMaterials}
                innerRadius={50}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value"
              >
                {dataPieChartMaterials.map((_entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      colorsDataPieMaterials[
                        index % colorsDataPieMaterials.length
                      ]
                    }
                  />
                ))}
              </Pie>
              <Legend
                verticalAlign="bottom"
                wrapperStyle={{ lineHeight: "40px" }}
              />
              <Tooltip formatter={(value) => value + " %"} />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" align="center" color="textSecondary">
            {" "}
            {selectDate === 0
              ? "Impacto porcentual por eco-equivalencias " +
                months[dateInit.getMonth()]
              : "Impacto porcentual por eco-equivalencias acumulado"}
          </Typography>
          <ResponsiveContainer width="100%" aspect={4.0 / 4.0}>
            <PieChart width={450} height={400}>
              <Pie
                data={dataPieEcoec}
                innerRadius={50}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value"
              >
                {dataPieEcoec.map((_entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colorsDataPieEcoeq[index % colorsDataPieEcoeq.length]}
                  />
                ))}
              </Pie>
              <Legend
                verticalAlign="bottom"
                wrapperStyle={{ lineHeight: "40px" }}
              />
              <Tooltip formatter={(value) => value + " %"} />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Card>
  );
}
