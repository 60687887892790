import React, { useEffect, useState } from "react";
import { Grid, Grow, Card, Button } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useSelector } from "react-redux";

import { useAppContext } from "../../../../context/AppContext";

import Scheduler from "./Scheduler";
import SchedulerTransporter from "./SchedulerTransporter";
import Nothing from "../Nothing";
import RemovalCardExtra from "../CardExtra";
import RemovalCard from "../Card";
import RemovalCardTransporter from "../CardTransporter";

import ModalViewRemoval from "../ViewModal";
import ViewModalTransporter from "../ViewModalTransporter";
import ModalCoordinateRemoval from "../CoordinateModal";
import ModalCoordinateScheduler from "../CoordinateSchedulerModal";
import TakeModal from "../TakeModal";

export default function RemovalsCurrent({ localID }) {
  const { userType, entityID } = useAppContext();

  const removals = useSelector((state) => state.removals);
  const [viewRemoval, setViewRemoval] = useState(false);
  const [coordinateRemoval, setCoordinateRemoval] = useState(false);
  const [takeRemovalScheduler, setTakeRemovalScheduler] = useState(false);
  const [coordinateRemovalScheduler, setCoordinateRemovalScheduler] = useState(false);
  const [datetimeRemoval, setDatetimeRemoval] = useState("0");
  const [responseRemovalList, setResponseRemovalList] = useState();
  const [removal, setRemoval] = useState({});
  const [data, setData] = useState({
    completes: [],
    discarded: [],
    accepted: [],
    incompletes: [],
    availables: [],
    extras: [],
    sensors: [],
    empty: [],
  });

  const getRemovalStatus = (removalTemp) => {
    let materials = 0;
    materials = removalTemp.materials?.reduce(
      (sum, current) => (sum += current.quantity),
      0
    );
    
    
    if(userType === "TRANSPORTER"){
      if (!removalTemp.transporterID) { //Este no fue asignado
        return 0;
      } else if (!removalTemp.payment) { //Este no tiene pago registrado
        return 1;
      } else if (!removalTemp.statusTransport) { //Este no ha sido respondido, es disponible
        return 2;
      } else if (removalTemp.statusTransport == "REJECTED") { //Este no ha sido asignado
        return 3;
      } else if (removalTemp.statusTransport == "ACCEPTED") { //Este fue acpetado
        return 4;
      } else if (removalTemp.statusTransport=="PENDING_VALID") { //Este no ha sido validado
        
        if (materials==0) { 
          return 8;
        } else{
          return 5;
        }
      } else  if (removalTemp.statusTransport=="COMPLETED") { //Este fue validado
        if (materials==0) { 
          return 8;
        } else{
          return 7;
        }
      }
    }else{
      if (!removalTemp.datetimeLastModification) {
        return 0;
      } else if (!removalTemp.transporterID) {
        return 1;
      } else if (!removalTemp.payment) {
        return 2;
      } else if (!materials) {
        return 3;
      } else if (materials) {
        return 4;
      }
    }
  
  };

  useEffect(() => {
    if (removals.current) {
      let disable = false;
      let status = 0;
      let availables = [];
      let incompletes = [];
      let discarded = [];
      let accepted = [];
      let completes = [];
      let extras = [];
      let sensors = [];
      let empty  = [];

      {userType === "TRANSPORTER" ? 
      
      removals.current.forEach((thisRemoval) => {
        
        status = getRemovalStatus(thisRemoval);
        
          if (status === 7 || status === 5) { //Para retiros completos o por validar
            completes.push({
              ...thisRemoval,
              status,
            });
          }else if (status === 2) {
            availables.push({
              ...thisRemoval,
              status,
            });
          }else if (status === 3) {
            discarded.push({
              ...thisRemoval,
              status,
            });
          }else if (status === 4) {
            
            accepted.push({
              ...thisRemoval,
              status,
            });
          }else if (status === 8) {
            
            empty.push({
              ...thisRemoval,
              status,
            });
          }
          
        })

       : 

      removals.current.forEach((thisRemoval) => {
        if (thisRemoval.author === "WEBAPP_EXTRA" && thisRemoval.status === "PENDING_TRANS") {
          extras.push(thisRemoval);
          return;
        }
        if (thisRemoval.status === "AVAILABLE") {
          if (thisRemoval.author === "SENSOR") {
            sensors.push({
              ...thisRemoval,
              status: 7
            });
          } // se debe mantener los disponibles pese a que algunos son solicitados por sensor
          availables.push({
            ...thisRemoval,
            status: disable ? 6 : 5,
          });
          disable = true;
        } else if (thisRemoval.status === "INACTIVE") {
          discarded.push({
            ...thisRemoval,
            status: 8
          });

        } else {
          status = getRemovalStatus(thisRemoval);
          if (status === 4) {
            completes.push({
              ...thisRemoval,
              status,
            });
          } else {
            incompletes.push({
              ...thisRemoval,
              status,
            });
          }
        }
      })};
      setData({
        availables,
        incompletes,
        discarded,
        completes,
        accepted,
        extras,
        sensors,
        empty
      });
    }
  }, [removals.current]);

  return (
    <Grid container>
      <Grid item xs={12}>
        {viewRemoval && (
          <ViewModalTransporter removal={removal} setViewRemoval={setViewRemoval} />
        )}
        {coordinateRemoval && (
          <ModalCoordinateRemoval
            removal={removal}
            setCoordinateRemoval={setCoordinateRemoval}
            localID={localID}
          />
        )}
        {coordinateRemovalScheduler && (
          <ModalCoordinateScheduler
            coordinateRemovalScheduler={coordinateRemovalScheduler}

            datetimeRemoval={datetimeRemoval}
            setCoordinateRemovalScheduler={setCoordinateRemovalScheduler}
            localID={localID}
          />
        )}
        {takeRemovalScheduler && (
          <TakeModal
            takeRemovalScheduler={takeRemovalScheduler}
            responseRemovalList={responseRemovalList}
            datetimeRemoval={datetimeRemoval}
            setTakeRemovalScheduler={setTakeRemovalScheduler}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        {/* 
        <Content
          removals={data.availables}
          setRemoval={setRemoval}
          setViewRemoval={setViewRemoval}
          setCoordinateRemoval={setCoordinateRemoval}
          text="Retiros disponibles"
          fallback={
            "No te quedan retiros disponibles este mes. Puedes solicitar un retiro extra presionando el botón de la esquina superior derecha"
          }
        />
        */}
        {userType === "TRANSPORTER" ? 
        <ContentSchedulerTransporter
        removals={data.availables}
          setRemoval={setRemoval}
          setDatetimeRemoval={setDatetimeRemoval}
          setTakeRemovalScheduler={setTakeRemovalScheduler}
          setResponseRemovalList={setResponseRemovalList}
          text="Retiros en Disponibles"
          fallback={"No hay en curso"}
      />
        :
        <ContentScheduler
          removals={data.availables}
          setDatetimeRemoval={setDatetimeRemoval}
          setCoordinateRemovalScheduler={setCoordinateRemovalScheduler}
          text="Retiros disponibles"
          fallback="No te quedan retiros disponibles este mes. Puedes solicitar un retiro extra presionando el botón de la esquina superior derecha"
        />}
      </Grid>

      <Grid item xs={12}>
      {userType === "TRANSPORTER" ? "" :
        <Content
          removals={data.sensors}
          setRemoval={setRemoval}
          setViewRemoval={setViewRemoval}
          setCoordinateRemoval={setCoordinateRemoval}
          text="Retiros solicitados sensor"
          fallback={"No hay retiros solicitados por sensor"}
        />}
      </Grid>

      <Grid item xs={12}>
      {userType === "TRANSPORTER" ? 
      <Grid item xs={12}>
      <ContentTransporter
        removals={data.discarded}
        setRemoval={setRemoval}
        setViewRemoval={setViewRemoval}
        setCoordinateRemoval={setCoordinateRemoval}
        text="Retiros rechazados"
        fallback={"No hay retiros para mostrar"}
      />
    </Grid>
      :
        <Content
          removals={data.discarded}
          setRemoval={setRemoval}
          setViewRemoval={setViewRemoval}
          setCoordinateRemoval={setCoordinateRemoval}
          text="Retiros descartados"
          fallback={"No hay retiros para mostrar"}
        />}
      </Grid>

      <Grid item xs={12}>
      {userType === "TRANSPORTER" ? 
      
      <ContentTransporter
          removals={data.accepted}
          setRemoval={setRemoval}
          setViewRemoval={setViewRemoval}
          setCoordinateRemoval={setCoordinateRemoval}
          text="Retiros en curso"
          fallback={"No hay retiros para mostrar"}
        />

      :
        <Content
          removals={data.incompletes}
          setRemoval={setRemoval}
          setViewRemoval={setViewRemoval}
          setCoordinateRemoval={setCoordinateRemoval}
          text="Retiros en curso"
          fallback={"No hay retiros para mostrar"}
        />}
      </Grid>

      <Grid item xs={12}>
      {userType === "TRANSPORTER" ? 
        <ContentTransporter
          removals={data.completes}
          setRemoval={setRemoval}
          setViewRemoval={setViewRemoval}
          setCoordinateRemoval={setCoordinateRemoval}
          text="Retiros completos"
          fallback={"No hay retiros para mostrar"}
        />
        :
        <Content
          removals={data.completes}
          setRemoval={setRemoval}
          setViewRemoval={setViewRemoval}
          setCoordinateRemoval={setCoordinateRemoval}
          text="Retiros completos"
          fallback={"No hay retiros para mostrar"}
        />}
      </Grid>

      <Grid item xs={12}>
      {userType === "TRANSPORTER" ? 
        <ContentTransporter
          removals={data.empty}
          setRemoval={setRemoval}
          setViewRemoval={setViewRemoval}
          setCoordinateRemoval={setCoordinateRemoval}
          text="Retiros sin material"
          fallback={"No hay retiros para mostrar"}
        />
        :
        <Content
          removals={data.empty}
          setRemoval={setRemoval}
          setViewRemoval={setViewRemoval}
          setCoordinateRemoval={setCoordinateRemoval}
          text="Retiros sin material"
          fallback={"No hay retiros para mostrar"}
        />}
      </Grid>

      <Grid item xs={12}>
      {userType === "TRANSPORTER" ? "" :
        <ContentTransporter
          removals={data.extras}
          setRemoval={setRemoval}
          setViewRemoval={setViewRemoval}
          setCoordinateRemoval={setCoordinateRemoval}
          text="Retiros extras"
          fallback={"No hay retiros para mostrar"}
        />}
      </Grid>
    </Grid>
  );
}

function Content({
  removals,
  setRemoval,
  setViewRemoval,
  setCoordinateRemoval,
  text,
  fallback,
}) {
  const [view, setView] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (removals) {
      setData(removals);
    }
  }, [removals]);
  return (
    <Card style={{ padding: 12, margin: 6 }}>
      <Grid container justify="space-between" direction="row">
        <Grid item>
          <Button
            onClick={() => setView(!view)}
            color="primary"
            startIcon={view ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            {text} ({data.length})
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="text" onClick={() => setView(!view)}>
            {!view ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </Button>
        </Grid>
      </Grid>
      {view && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <hr />
          </Grid>
          {!data.length && <Nothing text={fallback} component={<div />} />}
          {view &&
            data.map((removalData, i) => (
              removalData.status === 7 && <Grow key={i} in={true} timeout={500 + 500 * i}>
                <Grid item xs={12} sm={4} md={4} key={i}>
                  <RemovalCard
                    removal={removalData}
                    key={i}
                    setRemoval={setRemoval}
                    setViewRemoval={setViewRemoval}
                    setCoordinateRemoval={setCoordinateRemoval}
                  />
                </Grid>
              </Grow>
            ))}
          {view &&
            data.map((removalData, i) => (
              removalData.status === 8 && <Grow key={i} in={true} timeout={500 + 500 * i}>
                <Grid item xs={12} sm={4} md={4} key={i}>
                  <RemovalCard
                    removal={removalData}
                    key={i}
                    setRemoval={setRemoval}
                    setViewRemoval={setViewRemoval}
                    setCoordinateRemoval={setCoordinateRemoval}
                  />
                </Grid>
              </Grow>
            ))}
          {view &&
            data.map((removalData, i) => (
              removalData.status !== 7 && removalData.status !== 8 && <Grow key={i} in={true} timeout={500 + 500 * i}>
                <Grid item xs={12} sm={4} md={3} key={i}>
                  <RemovalCard
                    removal={removalData}
                    key={i}
                    setRemoval={setRemoval}
                    setViewRemoval={setViewRemoval}
                    setCoordinateRemoval={setCoordinateRemoval}
                  />
                </Grid>
              </Grow>
            ))}
        </Grid>
      )}
    </Card>
  );
}

function ContentTransporter({
  removals,
  setRemoval,
  setViewRemoval,
  setCoordinateRemoval,
  text,
  fallback,
}) {
  const [view, setView] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (removals) {
      setData(removals);
    }
  }, [removals]);
  return (
    <Card style={{ padding: 12, margin: 6 }}>
      <Grid container justify="space-between" direction="row">
        <Grid item>
          <Button
            onClick={() => setView(!view)}
            color="primary"
            startIcon={view ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            {text} ({data.length})
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="text" onClick={() => setView(!view)}>
            {!view ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </Button>
        </Grid>
      </Grid>
      {view && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <hr />
          </Grid>
          {!data.length && <Nothing text={fallback} component={<div />} />}
          {view &&
            data.map((removalData, i) => (
              removalData.status === 7 && <Grow key={i} in={true} timeout={500 + 500 * i}>
                <Grid item xs={12} sm={4} md={4} key={i}>
                  <RemovalCardTransporter
                    removal={removalData}
                    key={i}
                    setRemoval={setRemoval}
                    setViewRemoval={setViewRemoval}
                    setCoordinateRemoval={setCoordinateRemoval}
                  />
                </Grid>
              </Grow>
            ))}
          {view &&
            data.map((removalData, i) => (
              removalData.status === 8 && <Grow key={i} in={true} timeout={500 + 500 * i}>
                <Grid item xs={12} sm={4} md={4} key={i}>
                  <RemovalCardTransporter
                    removal={removalData}
                    key={i}
                    setRemoval={setRemoval}
                    setViewRemoval={setViewRemoval}
                    setCoordinateRemoval={setCoordinateRemoval}
                  />
                </Grid>
              </Grow>
            ))}
          {view &&
            data.map((removalData, i) => (
              removalData.status !== 7 && removalData.status !== 8 && <Grow key={i} in={true} timeout={500 + 500 * i}>
                <Grid item xs={12} sm={4} md={4} key={i}>
                  <RemovalCardTransporter
                    removal={removalData}
                    key={i}
                    setRemoval={setRemoval}
                    setViewRemoval={setViewRemoval}
                    setCoordinateRemoval={setCoordinateRemoval}
                  />
                </Grid>
              </Grow>
            ))}
        </Grid>
      )}
    </Card>
  );
}

function ContentExtra({
  removals,
  setRemoval,
  setViewRemoval,
  setCoordinateRemoval,
  text,
  fallback,
}) {
  const [view, setView] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (removals) {
      setData(removals);
    }
  }, [removals]);
  return (
    <Card style={{ padding: 12, margin: 6 }}>
      <Grid container justify="space-between" direction="row">
        <Grid item>
          <Button
            onClick={() => setView(!view)}
            color="primary"
            startIcon={view ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            {text} ({data.length})
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="text" onClick={() => setView(!view)}>
            {!view ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </Button>
        </Grid>
      </Grid>
      {view && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <hr />
          </Grid>
          {!data.length && <Nothing text={fallback} component={<div />} />}
          {view &&
            data.map((removalData, i) => (
              <Grow key={i} in={true} timeout={500 + 500 * i}>
                <Grid item xs={12} sm={6} md={6} key={i}>
                  <RemovalCardExtra
                    removal={removalData}
                    key={i}
                    setRemoval={setRemoval}
                    setViewRemoval={setViewRemoval}
                    setCoordinateRemoval={setCoordinateRemoval}
                  />
                </Grid>
              </Grow>
            ))}
        </Grid>
      )}
    </Card>
  );
}

function ContentSchedulerTransporter({
  removals,
  setDatetimeRemoval,
  setResponseRemovalList,
  setTakeRemovalScheduler,
  text,
  fallback,
}) {
  const [view, setView] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (removals) {
      setData(removals);
    }
  }, [removals]);

  return (
    <Card style={{ padding: 12, margin: 6 }}>
      <Grid container justify="space-between" direction="row">
        <Grid item>
          <Button
            onClick={() => setView(!view)}
            color="primary"
            startIcon={view ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            {text} ({data.length})
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="text" onClick={() => setView(!view)}>
            {!view ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </Button>
        </Grid>
      </Grid>
      {view && (
        <React.Fragment>
          <Grid item xs={12}>
            <hr />
          </Grid>
          {!data.length ? <Nothing text={fallback} component={<div />} />
            :
            <SchedulerTransporter
              removals={removals}
              setDatetimeRemoval={setDatetimeRemoval}
              setResponseRemovalList={setResponseRemovalList}
              setTakeRemovalScheduler={setTakeRemovalScheduler}
            />
          }
        </React.Fragment>
      )}
    </Card>
  );
}

function ContentScheduler({
  removals,
  setDatetimeRemoval,
  setCoordinateRemovalScheduler,
  text,
  fallback,
}) {
  const [view, setView] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (removals) {
      setData(removals);
    }
  }, [removals]);

  return (
    <Card style={{ padding: 12, margin: 6 }}>
      <Grid container justify="space-between" direction="row">
        <Grid item>
          <Button
            onClick={() => setView(!view)}
            color="primary"
            startIcon={view ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            {text} ({data.length})
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="text" onClick={() => setView(!view)}>
            {!view ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </Button>
        </Grid>
      </Grid>
      {view && (
        <React.Fragment>
          <Grid item xs={12}>
            <hr />
          </Grid>
          {!data.length ? <Nothing text={fallback} component={<div />} />
            :
            <Scheduler
              removals={removals}
              setDatetimeRemoval={setDatetimeRemoval}
              setCoordinateRemovalScheduler={setCoordinateRemovalScheduler}
            />
          }
        </React.Fragment>
      )}
    </Card>
  );
}
