import React from "react";
import Axios from "../api/Axios";
import Materials from "../resources/materials";
const AppContext = React.createContext();

export function AppContextProvider(props) {
  const [loged, setLoged] = React.useState(false);
  const [logoutDialog, setLogoutDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [userData, setUserData] = React.useState({});
  const [themeColor, setThemeColor] = React.useState(
    window.localStorage.getItem("themeColor")
  );
  const [headerData, setHeaderData] = React.useState({
    title: "",
    subtitle: "",
    urlLogo: "",
  });
  const [notification, setNotification] = React.useState({
    open: false,
    message: "",
    title: "",
    severity: "success",
  });
  const [loginStatus, setLoginStatus] = React.useState({
    step: "DONE",
    error: false,
  });

  const helpers = Materials.materials;
  const userType = window.localStorage.getItem("userType");
  const transporterID = window.localStorage.getItem("transporterID");
  const userID = window.localStorage.getItem("userID");
  const entityID = window.localStorage.getItem("entityID");
  const token = window.localStorage.getItem("token");

  React.useState(() => {
    if (token) {
      loadData();
      checkTokenFunction({ setLoged, reInitVars });
    }
  }, []);

  React.useEffect(() => {
    window.localStorage.removeItem("themeColor");
    window.localStorage.setItem("themeColor", themeColor);
  }, [themeColor]);

  const login = async ({ email, password }) => {
    // CONTROLANDO FRONTEND
    await setLoginStatus({ step: "LOADING", error: false });
    let data = await loginFunction({ email, password });
    if (data.success) {
      await setLoginStatus({ step: "LOAD", error: false });
      // GUARDANDO DATOS
      
      await window.localStorage.setItem("token", data.payload.token);
      await window.localStorage.setItem("userType", data.payload.type);
      await window.localStorage.setItem("transporterID", data.payload.transporterID);
      await window.localStorage.setItem("userID", data.payload.id);
      await window.localStorage.setItem("entityID", data.payload.entityID);
      await reloadData(data.token);
      window.location.reload();
    } else {
      await setLoginStatus({ step: "DONE", error: true });
      setTimeout(() => {
        setLoginStatus({ step: "DONE", error: false });
      }, 2000);
    }
  };

  const logout = async () => {
    await logoutFunction();
    await setLogoutDialog(true);
    setTimeout(() => {
      reInitVars();
      setLogoutDialog(false);
    }, 3000);
  };

  function loadData() {
    let user_ = JSON.parse(window.localStorage.getItem("user"));
    
    let userData_ = JSON.parse(window.localStorage.getItem("userData"));
    setUser(user_);
    setUserData(userData_);
    if (userType === "CUSTOMER") {
      setHeaderData({
        title: userData_?.brand,
        subtitle: "Usuario matriz",
        urlLogo: userData_?.urlLogo,
      });
    } else if (userType === "TRANSPORTER") {
      setHeaderData({
        title: "Home transportista",
        subtitle: userData_?.address,
        urlLogo: userData_?.customerID?.urlLogo,
      });
    } else {
      setHeaderData({
        title: "Sede: " + userData_?.name,
        subtitle: userData_?.address,
        urlLogo: userData_?.customerID?.urlLogo,
      });
    }
  }

  const reloadData = async (token) => {
    try {
      const data = await Axios.get("/webApp/profile", {
        headers: {
          authorization: token ? token : window.localStorage.getItem("token"),
        },
      });
      await window.localStorage.removeItem("user");
      await window.localStorage.removeItem("userData");
      await window.localStorage.setItem("user", JSON.stringify(data.data.user));
      await window.localStorage.setItem(
        "userData",
        JSON.stringify(data.data.data)
      );
      loadData();
    } catch (e) {
      console.log(e);
    }
  };

  function reInitVars() {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("userType");
    window.localStorage.removeItem("userData");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("transporterID");
    window.localStorage.removeItem("userID");
    setLoged(false);
  }

  const value = React.useMemo(() => {
    return {
      token,
      userType,
      transporterID,
      userID,
      userData,
      entityID,
      login,
      logout,
      loged,
      loginStatus,
      logoutDialog,
      loading,
      headerData,
      setLoading,
      notification,
      setNotification,
      reloadData,
      user,
      themeColor,
      setThemeColor,
      helpers,
    };
  }, [
    loged,
    loginStatus,
    logoutDialog,
    loading,
    notification,
    headerData,
    userData,
    user,
    headerData,
    themeColor,
  ]);

  return <AppContext.Provider value={value} {...props} />;
}

export function useAppContext() {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error("NO SUSCRITO AL CONTEXTO");
  }
  return context;
}

const logoutFunction = async () => {
  try {
    await Axios.post("/auth/logout");
    return true;
  } catch (_err) {
    return false;
  }
};
const checkTokenFunction = async ({ setLoged, reInitVars }) => {
  try {
    await Axios.post("/auth/token_check");
    setLoged(true);
  } catch (err) {
    reInitVars();
    setLoged(false);
  }
};
const loginFunction = async ({ email, password }) => {
  try {
    let response = await Axios.post("/auth/login", { email, password });
    if (response.data.type !== "LOCAL" && response.data.type !== "CUSTOMER" && response.data.type !== "TRANSPORTER") {
      return {
        success: false,
        message: "NOT_ADMIN_TYPE",
      };
    }
    return {
      success: true,
      payload: response.data,
    };
  } catch (err) {
    alert("Correo y/o Contraseña incorrecta");
    return {
      success: false,
      message: err,
    };
  }
};
