import React, { useContext } from "react";
import { useAppContext } from "./AppContext";
import Axios from "../api/Axios";

const ProfileContext = React.createContext();

export function ProfileContextProvider(props) {
  const {
    user,
    userData,
    userType,
    setNotification,
    reloadData,
  } = useAppContext();

  const [profileData, setProfileData] = React.useState({});

  React.useEffect(() => {
    
    if (!userData && !user) {
      return;
    }
    if (userType === "LOCAL") {
      setProfileData({
        userName: user.name,
        userLastName: user.lastName,
        userEmail: user.email,
        userPhone: user.phone,
        localName: userData.name,
        localAddress: userData.address,
        localCommune: userData.commune,
      });
    } else if(userType === "TRANSPORTER") {
      setProfileData({
        userName: user.name,
        userLastName: user.lastName,
        userEmail: user.email,
        userPhone: user.phone,
      });
    } else {
      setProfileData({
        userName: user.name,
        userLastName: user.lastName,
        userEmail: user.email,
        userPhone: user.phone,
        brand: userData.brand,
        rut: userData.rut,
        address: userData.address,
        commune: userData.commune,
        companyLogo: userData.urlLogo,
      });
    }
  }, [user, userData, userType]);

  const changePassword = ({ password, newPassword }) => {
    return changePasswordFunction({ password, newPassword, setNotification });
  };

  const updateProfileData = (data) => {
    var payload = {};
    if (userType === "LOCAL") {
      payload = {
        password: data.password,
        user: {
          name: data.userName,
          lastName: data.lastName,
          phone: data.userPhone,
        },
        local: {
          name: data.localName,
          address: data.localAddress,
          commune: data.localCommune,
        },
      };
      return updateProfileDatafunction({
        payload,
        setNotification,
        reloadData,
      });
    } else {
      payload = {
        password: data.password,
        user: {
          name: data.userName,
          lastName: data.lastName,
          phone: data.userPhone,
        },
        customer: {
          brand: data.brand,
          address: data.address,
          rut: data.rut,
          commune: data.commune,
          companyLogo: data.companyLogo,
        },
      };
      return updateProfileDatafunction({
        payload,
        setNotification,
        reloadData,
      });
    }
  };

  const value = React.useMemo(() => {
    return {
      profileData,
      changePassword,
      updateProfileData,
    };
  }, [profileData]);

  return <ProfileContext.Provider value={value} {...props} />;
}

export function useProfileContext() {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("NO SUSCRITO AL CONTEXTO");
  }
  return context;
}

const changePasswordFunction = ({ password, newPassword, setNotification }) => {
  return new Promise((resolve, reject) => {
    Axios.post("/webApp/profile/changePassword", { password, newPassword })
      .then((response) => {
        setNotification({
          severity: "success",
          title: "Contraseña",
          message: "Cambiada con éxito",
          open: true,
        });
        resolve(response);
      })
      .catch((err) => {
        setNotification({
          severity: "error",
          title: "Contraseña",
          message: "No se ha podido cambiar",
          open: true,
        });
        reject(err);
      });
  });
};

const updateProfileDatafunction = ({
  payload,
  setNotification,
  reloadData,
}) => {
  return new Promise((resolve, reject) => {
    Axios.patch("/webApp/profile/", payload)
      .then((response) => {
        setNotification({
          severity: "success",
          title: "Perfil",
          message: "Actualizado con éxito",
          open: true,
        });
        reloadData();
        resolve(response);
      })
      .catch((err) => {
        setNotification({
          severity: "error",
          title: "Perfil",
          message: "No actualizado",
          open: true,
        });
        reject(err);
      });
  });
};
