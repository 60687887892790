import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  makeStyles,
} from "@material-ui/core";
import Fade from "react-reveal/Fade";

import { useAppContext } from "../../context/AppContext";
import logo from "../../resources/images/logo.png";

const useStyles = makeStyles(({ breakpoints }) => ({
  welcomeLogo: {
    height: 180,
    [breakpoints.down("md")]: {
      height: 124,
      width: "100%",
    },
  },
}));

export default function Login() {
  const { user } = useAppContext();
  const styles = useStyles();
  
  return (
    <div
      style={{
        top: 0,
        left: 0,
        position: "absolute",
        height: "100%",
        width: "100%",
        backgroundImage: `linear-gradient(74deg, rgba(0,0,0,0.2) 0%, rgba(0,10,6,0.2) 82%), url(${"https://images4.alphacoders.com/103/thumb-1920-1038769.jpg"})`,
      }}
    >
      <Dialog
        open={true}
        onClose={null}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent>
          <Fade>
            <Grid container style={{ maxWidth: "100%" }}>
              <Grid item align="center" xs={12}>
                <img
                  className={styles.welcomeLogo}
                  src={logo}
                  style={{ marginBottom: 20 }}
                  alt="Acción Circular"
                />
              </Grid>
            </Grid>
            <Grid item align="center" xs={12}>
              <Typography variant="h3" style={{color:"white"}}>Hasta pronto {user?.name}</Typography>
            </Grid>
          </Fade>
        </DialogContent>
      </Dialog>
    </div>
  );
}
