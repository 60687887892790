import React from "react";
import Jimp from "jimp";
import { Button } from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";

export default function UploadImage({ name, setFormData, formData }) {
  const handleSetImages = (event) => {
    Array.from(event.target.files).forEach((file) => {
      Jimp.read(URL.createObjectURL(file))
        .then((photo) => {
          photo
            .quality(100)
            .getBase64Async(Jimp.MIME_PNG)
            .then((res) => {
              setFormData({ ...formData, [name]: res });
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  return (
    <div>
      <img alt="logo" src={formData[name]} width={"100%"} />
      <br />
      <input
        accept="image/png"
        style={{ display: "none", width: "100%" }}
        id="raised-button-file-2"
        type="file"
        onChange={(event) => handleSetImages(event)}
      />
      <label htmlFor="raised-button-file-2" style={{ width: "100%" }}>
        <Button
          variant="outlined"
          color="primary"
          style={{
            marginTop: 8,
            height: 40,
          }}
          fullWidth
          startIcon={<CloudUploadOutlinedIcon />}
          component="span"
        >
          Actualizar logo
        </Button>
      </label>
    </div>
  );
}
