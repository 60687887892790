import React from "react";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const cards = [
  {
    name: "PLAN_BIRD",
    url: "https://image.flaticon.com/icons/svg/427/427487.svg",
    background:
      "linear-gradient(34deg, rgba(43,43,43,1) 0%, rgba(249,72,81,1) 29%, rgba(255,208,57,1) 92%)",
  },
  {
    name: "PLAN_ELEPHANT",
    url: "https://image.flaticon.com/icons/svg/490/490123.svg",
    background:
      "linear-gradient(34deg, rgba(43,43,43,1) 0%, rgba(50,129,41,1) 29%, rgba(106,255,0,1) 92%)",
  },
  {
    name: "PLAN_WOLF",
    url: "https://image.flaticon.com/icons/svg/616/616457.svg",
    background:
      "linear-gradient(34deg, rgba(32,2,65,1) 0%, rgba(59,106,209,1) 29%, rgba(37,255,174,1) 92%)",
  },
  {
    name: "PLAN_WHALE",
    url: "https://image.flaticon.com/icons/svg/2439/2439756.svg",
    background:
      "linear-gradient(34deg, rgba(32,2,65,1) 0%, rgba(0,149,154,1) 29%, rgba(35,0,14,1) 92%)",
  },
];
const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  card: {
    width: "100%",
    borderRadius: spacing(1), // 16px
    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    position: "relative",
    overflow: "initial",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    paddingLeft: 8,
    paddingRight: 8,
    [breakpoints.up("sm")]: {
      textAlign: "left",
      flexDirection: "row-reverse",
    },
    margin: 0,
  },
  media: {
    flexShrink: 0,
    width: "20%",
    paddingTop: "10%",
    marginLeft: "auto",
    marginRight: "auto",
    [breakpoints.up("sm")]: {
      marginRight: "initial",
    },
  },
  overline: {
    lineHeight: 2,
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: "0.7rem",
    opacity: 0.7,
  },
  heading: {
    fontWeight: "900",
    color: "#ffffff",
    letterSpacing: 0.5,
  },
  button: {
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    borderRadius: 100,
    paddingLeft: 32,
    paddingRight: 32,
    color: "#ffffff",
    textTransform: "none",
    width: "100%",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.32)",
    },
    [breakpoints.up("sm")]: {
      width: "auto",
    },
  },
}));

export default function RewardCard({ suscription }) {
  const styles = useStyles();
  const [card, setCard] = React.useState({});

  React.useEffect(() => {
    if (suscription.name) {
      setCard(
        cards.filter(
          (card) =>
            card.name === suscription?.name?.replace(" ", "_").toUpperCase()
        )[0]
      );
    }
  }, [suscription]);
  return (
    <Card className={styles.card} style={{ background: card.background }}>
      <CardMedia
        className={styles.media}
        style={{
          backgroundSize: "60%",
        }}
        image={card.url}
      />
      <CardContent className={styles.content}>
        <Typography className={styles.overline} variant={"overline"}>
          Suscripción contratada
        </Typography>
        <Typography className={styles.heading} variant={"h4"} gutterBottom>
          {suscription.name}
        </Typography>
        {/* <Button className={styles.button} startIcon={<ArrowUpwardIcon />}> */}
          {/* {suscription.name === "Plan Whale" ? "Cambiar plan" : "Mejorar plan"} */}
        {/* </Button> */}
      </CardContent>
    </Card>
  );
}
