
import React,{useEffect} from "react";
import { Grid, Card, CardContent, Grow, Typography} from "@material-ui/core";
import {StoreIcon, AddCircleOutlineOutlined} from "@material-ui/icons/Store";
import Header from "../../Navigation/Header";
import Tabla from "./Tabla";
import { DropzoneArea } from "material-ui-dropzone";
import { useSelector, useDispatch } from "react-redux";
import learningActions from "../../../redux/actions/learning"
import Jimp from "jimp";

function Index({formData, setFormData}) {

const [viewState, setViewState] = React.useState(false);
const dispatch = useDispatch();
const learning = useSelector((state) => state.learning);
    useEffect(() => {
      const start = async () => {
        dispatch(learningActions.fetchLearning());
      };
      start();
    }, []);

const handleClose = () => {
  setViewState(false);
};

const handleSetImages = (files) => {
  Array.from(files).forEach((file) => {
    setTimeout(() => {
      Jimp.read(URL.createObjectURL(file))
        .then((video) => {
          video
            .contain(800, 800)
            .quality(100)
            .getBase64Async(Jimp.MIME_JPEG)
            .then((res) => {
              setFormData({ ...formData, image: res });
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => {
          console.error(err);
        });
    }, 1000);
  });
};

  return (
    <Card>
      <CardContent>
        <Header
          title={
            <Typography component="h2" variant="h4">Aprendizaje
            </Typography>
          }
        />
        <hr />
            <Tabla />
      </CardContent>
    </Card>
  );
}

export default Index