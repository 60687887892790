import React, { Fragment } from "react";
import { Button, ButtonGroup, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";






export const ButtonSwitch = ({
  view,
  setView,
  name,
  textPositive,
  textNegative,
}) => {
  return (
    <Fragment>
      <hr />
      <Button
        color="primary"
        onClick={() => setView({ ...view, [name]: !view[name] })}
      >
        {view[name] ? textNegative : textPositive}
      </Button>
    </Fragment>
  );
};

export const ButtonGroupAction = ({
  handleClose,
  handleSuccess,
  isLastStep,
  _handleNext,
  _handleBack,
  view,
  sendButtonEnabled,
  activeStep,
  formData,
}) => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    card: {
      width: "100%",
      maxWidth: 500,
      transition: "0.3s",
      boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
      position: "relative",
      overflow: "initial",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      paddingLeft: 8,
      paddingRight: 8,
      background:
        "linear-gradient(34deg, rgba(43,43,43,1) 0%, rgba(50,129,41,1) 29%, rgba(106,255,0,1) 92%)",
      [breakpoints.up("sm")]: {
        textAlign: "left",
        flexDirection: "row-reverse",
      },
    },
    media: {
      flexShrink: 0,
      width: "20%",
      paddingTop: "15%",
      marginLeft: "auto",
      marginRight: "auto",
      [breakpoints.up("sm")]: {
        marginRight: "initial",
      },
    },
    overline: {
      lineHeight: 2,
      color: "#ffffff",
      fontWeight: "bold",
      fontSize: "0.7rem",
      opacity: 0.7,
    },
    heading: {
      fontWeight: "400",
      color: "#ffffff",
      letterSpacing: 0.5,
    },
    fail: {
      background:"rgb(255, 0, 0, 92%)",
      color: "#fff",
      fontSize: "0.85em"
    },
  }));
  const classes = useStyles();
  return (
    <ButtonGroup size="small">
      <Button
        onClick={handleClose}
        color="secondary"
        variant="contained"
        startIcon={<ClearIcon />}
        className={classes.fail}
        
      >
        Cancelar
      </Button>

      {activeStep !== 0 && (
      <Button
          onClick={_handleBack}
          color="primary"
          variant={formData.localID ? "contained" : "outlined"}
          startIcon={<NavigateBefore />}
        >
          Atrás
        </Button>
        )}
      {isLastStep?(
        sendButtonEnabled?(
        <Button
          onClick={handleSuccess}
          color="primary"
          variant={formData.localID ? "contained" : "outlined"}
          startIcon={<CheckIcon />}
        >
          Enviar
        </Button>
        ):""
        

      ):(
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<NavigateNext />}
          onClick={_handleNext}
        >
            Siguiente
        </Button>
      )}
    </ButtonGroup>
  );
};

export const Notes = ({ formData, setFormData }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          value={formData.notes}
          rows={6}
          multiline
          label="Notas"
          onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
};
