import React from "react";
import { Grid, Typography } from "@material-ui/core";

export default function Conditions() {
  return (
    <Grid item container direction="row" justify="center" alignItems="center">
      <Typography>
        <strong>1) DESCRIPCIÓN:</strong> Acción Circular es una empresa de tecnología para el reciclaje, que
        automatiza y optimiza la gestión de residuos, conectando empresas productoras, puntos
        de venta, recolectores y valorizadores, a través de una plataforma virtual, así como todos
        los servicios complementarios al uso de dicha aplicación informática, tales como
        alojamiento de datos, actualizaciones de la aplicación y servicio de soporte en horario
        laboral, de conformidad con lo estipulado en el contrato respectivo.
      </Typography>
      <br />
      <Typography>
        <strong>2) OBJETO:</strong> El objeto de la plataforma Acción Circular es, esencialmente, la promoción y
        colaboración entre actores claves de la industria y la sociedad en relación al reciclaje, todo
        con el fin de construir ciudades más inteligentes, responsables, con soluciones
        tecnológicas inspiradas en la economía circular, realizando retiros de material reciclable
        como, por ejemplo, botellas de PET, vidrio, botellas y cartones, aluminio y tetrapak, a
        través de un modelo de suscripción mensual a la plataforma online, además de ofrecer el
        servicio de instalación de sensores ultrasónicos para reciclaje inteligente y consultoría
        medioambiental.
      </Typography>
      <br />
      <Typography>
        <strong>3) USO DE LA PLATAFORMA: </strong>La prestación de este servicio no implica ni directa ni
          indirectamente cesión alguna al <strong>Cliente</strong> de derechos de propiedad intelectual de ningún
          tipo sobre la citada aplicación informática, que quedan expresamente reservados a su
          legítimo titular.
          Se deja constancia que las claves de acceso a <strong>Acción Circular</strong> pertenecen exclusivamente
          al <strong>Cliente</strong>, que se compromete a hacer un uso diligente de las mismas, así como a
          mantenerlas en secreto, no responsabilizándose Acción Circular del mal uso que el <strong>Cliente</strong>
          pueda hacer de dichas claves de acceso ni de los daños que se puedan ocasionar por dicha
          circunstancia.
          Asimismo, el <strong>Cliente</strong> deberá comunicar a Acción Circular, la pérdida, robo o cualquier
          incidencia de las claves de acceso a efectos de que las desactive e inhabilite, y

          proporcionar, en su caso, una nueva clave al Cliente, todo ello a fin de garantizar el
          correcto acceso a los datos e impedir accesos no autorizados.
      </Typography>
      <br />
      <Typography>
        <strong>4) OBLIGACIONES DEL CLIENTE:</strong> Por contratar con Acción Circular, el Cliente se
        compromete a:
        <br />
        a) Utilizar la <strong>Plataforma Acción Circular</strong> para solicitar retiros;
        <br />
        b) Contar con fotografías e información verídica e íntegra, esta puede ser informes de
        terceros o visitas de la Empresa, que permitan un correcto diagnóstico de residuos o
        diagnóstico del retiro extraordinario por parte de Acción Circular, facilitando la estimación
        para el pago por suscripción/retiro extraordinario en relación con volumen y retiro de
        material.
        <br />
        c) Suministrar la información veraz y oportuna requerida por la <strong>Empresa</strong> de servicios para
        la realización de trabajos acordados.
        <br />
        d) En caso de requerirse, brindar los espacios y equipamiento necesario para la ejecución
        del servicio.
        <br />
        e) Mantener limpios y separados los materiales que disponga para ser reciclados por tipo
        de material, según instructivo facilitado por la <strong>Empresa</strong> y clasificación de materiales del
        Ministerio del Medio Ambiente, en bolsas o maxisacas, adquiridas por parte del <strong>Cliente</strong>.
        Además de disponer los materiales accesibles para el Transportista al momento de retiro
        del reciclaje (cerca del estacionamiento del vehículo, por ejemplo). <strong>El Transportista puede
        rechazar los materiales a reciclar en caso de que estos se encuentren en pésimo estado
        de segregado, o en malas condiciones, como sucios, con restos de comida, líquido, o no
        dejados correctamente en contenedores, maxisacas o espacios segregados</strong>. La <strong>Empresa</strong>
        no se responsabilizará por el reciclaje mal segregado, ya que es responsabilidad del
        <strong>Cliente</strong> realizar esta labor. Esto, entendiendo que la <strong>Empresa</strong> busca crear y difundir la
        educación de reciclaje, además de buscar un espíritu de colaboración y empatía entre los
        actores de la cadena del reciclaje.
      </Typography>
      <br />
      <Typography>
        <strong>5) RETIROS EXTRAORDINARIOS:</strong> Los retiros extraordinarios pueden tratarse de dos tipos:
        <br />
        a) Un retiro de reciclaje que se trata de materiales poco comunes, por ejemplo
        electrodomésticos, chatarra, tela, madera o cualquier material no contemplado dentro del
        plan contratado por el cliente.
        <br />
        b) Un retiro de reciclaje que no estaba calendarizado o agendado previamente dentro del
        plan contratado.
        Estos retiros tienen un valor más alto que los acordados con el cliente en el contrato, y el
        valor es cotizado una vez que se solicita el retiro extraordinario en la plataforma o
        directamente con la contraparte del área comercial de Acción Circular. Las variables que
        se tendrán en cuenta para fijar el valor de estos retiros extraordinarios son el tipo de
        material, volumen y urgencia del retiro solicitado, por lo que se cotizarán de acuerdo al
        caso específico y determinado.
      </Typography>
      <br />
      <Typography>
        <strong>6) RETIROS HECHOS POR RECOLECTORES DE BASE O TRANSPORTISTAS: </strong> Si bien Acción
        Circular privilegia los retiros realizados por recolectores de base, no se limita a la
        subcontratación de servicios para retiros especiales como residuos peligrosos, basura o
        transportes de emergencia. Dependiendo de la situación, pueden implicar una variación
        en el costo del servicio, lo que será comunicado oportunamente a quien corresponda.
      </Typography>
      <br />
      <Typography>
        <strong>7) TRAZABILIDAD Y CERTIFICADOS DE DISPOSICIÓN:</strong> Si bien Acción Circular cuenta con
        una doble verificación de los materiales y pesos reciclados, tanto por el transportista
        como por la planta valorizadora, es posible que existan retrasos o desfases en la recepción
        de certificados de trazabilidad, dada la naturaleza de la forma en que trabajan los
        recolectores de base o las mismas fechas de entrega que manejan internamente las
        plantas de reciclaje. Esto podría significar un retraso en la actualización de los pesos del
        material retirado en la plataforma de Acción Circular, manteniendo el estado de un retiro
        como “Incompleto”, lo que significa que se ejecutó el retiro, pero no se han actualizado
        los pesos y trazabilidad.
      </Typography>
      <br />
      <Typography>
        <strong>8) CAMBIOS EN LOS TÉRMINOS Y CONDICIONES:</strong> El Cliente reconoce y acepta que Acción
        Circular o los proveedores pueden actualizar los términos y condiciones, así como los
        servicios prestados, o emitir nuevas versiones de un servicio por diversas razones, mejorar
        los servicios, y realizar actualizaciones o presentar nuevas versiones, para lo cual se
        reserva el derecho de añadir o quitar características o funcionalidades.
      </Typography>
    </Grid>
  );
}

