import React, { useEffect, useState } from "react";
import Axios from "../../../../api/Axios";
import { TextField, Grid, MenuItem, Typography, Chip, formatMs } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import MomentUtils from "@date-io/moment";
import Jimp from "jimp";
import { DropzoneArea } from "material-ui-dropzone";

const style = {
  margin: "dense",
  variant: "outlined",
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
};

export default function General({ formData, setFormData, removal }) {
  const utils = useSelector((state) => state.utils);
  const locals = useSelector((state) => state.locals);
  


  const [vehiclesOptions, setVehiclesOptions] = useState([]);
  const [autocompleteVehicle, setAutocompleteVehicle] = useState({});
  
  const [autocompleteValues, setAutocompleteValues] = useState({
    local: "",
    transporter: "",
    disposition: "",
  });

  useEffect(() => {
    
    if (formData.localID || formData.transporterID || formData.dispositionID) {
      setAutocompleteValues({
        ...autocompleteValues,
        local: utils.locals.find((local) => local._id === formData.localID),
        disposition: utils.dispositions.dispositions.find((disposition) => disposition._id === formData.dispositionID),
      });
    }

  }, [formData.localID, formData.transporterID, formData.dispositionID]);

  useEffect(() => {

    
    
    if (utils.transporters) {
      utils.transporters.map((transporter) => {
        transporter.vehiclesID.map((vehicle) => {
          if (vehicle._id === formData.vehicleID) {
            setAutocompleteVehicle(vehicle);
          }
        })
      })
    }
  }, [utils.transporters])


  const translateAuthor = (author) => {
    if (author === "ADMIN") {
      return "admin";
    }
    if (author === "WEBAPP_SUSCRIPTION") {
      return "usr susc";
    }
    if (author === "WEBAPP_EXTRA") {
      return "usr extra";
    }
  };

  

  const handleChangeTransporter = (k) => {
    setFormData({ ...formData, transporterID: k._id });
    // setAutocompleteVehicle({});
    // setFormData({ ...formData, vehicleID: null });
    setVehiclesOptions(utils.transporters.filter(transporter => transporter._id === k._id)[0].vehiclesID);
  };

  const handleVehicle = (k) => {
    setAutocompleteVehicle(k);
    setFormData({ ...formData, vehicleID: k._id })
  };


  async function handleLoadFileVoucher1(e) {
    console.log(formData);
    var files = e.target.files;
    var file = null;
    console.log(files);
    console.log(files[0]);
    console.log(files[0].type);

    const dat = new FormData();
    dat.append('file',files)
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    let response = await Axios.post("/adminTool/removalsImages", dat, config);
    console.log(response);

  };


  function goVoucher(voucher) {
    // window.open();
    window.location.href = voucher;
  }

  function setDateIn00(date) {
    const aux = new Date(date);
    aux.setHours(0);
    aux.setMinutes(0);
    aux.setSeconds(1);
    return aux;
  };

  function ItemDisposition(params) {
    return (
      <TextField
        {...params}
        margin="dense"
        label="Planta de dispisición final"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  };

  function ItemVehicles(params) {
    return (
      <TextField
        {...params}
        margin="dense"
        label="Placa patente"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6">General</Typography>
      </Grid>
      <Grid item xs={6} align="right">
        <Chip
          label={formData.author ? translateAuthor(formData.author) : "admin"}
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
      <Typography variant="body2" align="center" color="textSecondary"> Empresa: </Typography>
      <Typography variant="body2" align="center" color="textSecondary">  Fábrica Cartones</Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
      <Typography variant="body2" align="center" color="textSecondary"> Local: </Typography>
      <Typography variant="body2" align="center">{removal.localID.name} </Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
      <Typography variant="body2" align="center" color="textSecondary"> Pago: </Typography>
      <Typography variant="body2" align="center" color="textSecondary"> ${Intl.NumberFormat('de-DE', {
          
          maximumFractionDigits: 0
      }).format(removal.payment)} </Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
      <Typography variant="body2" align="center" color="textSecondary">Dirección: </Typography>
      <Typography variant="body2" align="center">{removal.localID.address} </Typography>
      </Grid> 
      <Grid item xs={12}>
        <hr></hr>
        <Typography variant="h6">Horario</Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
      <Typography variant="body2" align="center" color="textSecondary">Fecha del retiro: </Typography>
      <Typography variant="body2" align="center">{new Date(removal.datetimeRemoval).toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} </Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
      <Typography variant="body2" align="center" color="textSecondary">Ventana de tiempo: </Typography>
      <Typography variant="body2" align="center">{removal.window=="A"?"9:00 a 13:00":"14:00 a 19:00"} </Typography>
      </Grid> 
     
  
        
  </Grid>
);
}
