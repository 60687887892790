import React from "react";
import { Typography } from "@material-ui/core";
import {
  // DESARROLLO ANTERIOR

  // Battery20,
  // Battery30,
  // Battery50,
  // Battery60,
  // Battery80,
  // BatteryFull,
  //Validación nueva
  Battery20,
  Battery30,
  Battery60,
  Battery80,
  Battery90,
  BatteryFull,
} from "@material-ui/icons";
import PropTypes from "prop-types";

export default function Battery({ battery }) {
  return (

      //Validación nueva
    <Typography variant="body2" color="textSecondary">
      {battery <= 30 ? (
        <Battery20 style={{ color: "#ff4444" }} />
      ) : battery >= 31 && battery <= 60 ? (
        <Battery30 style={{ color: "#ffbb33" }} />
      ) : battery >= 61 && battery <= 80 ? (
        <Battery60 style={{ color: "#00C851" }} />
      ) : battery > 80 && battery <= 90 ? (
        <Battery80 style={{ color: "#00C851" }} />
      ) : battery > 90 && battery <= 99 ? (
        <Battery90 style={{ color: "#00C851" }} />
      ) : (
        <BatteryFull style={{ color: "#00C851" }} />
      )}
      {" " + battery + "%"}
    </Typography>

      // VALIDACION DESARROLLO ANTERIOR
      // <Typography variant="body2" color="textSecondary">
      //   {battery <= 20 ? (
      //     <Battery20 style={{ color: "#ff4444" }} />
      //   ) : battery > 20 && battery <= 30 ? (
      //     <Battery30 style={{ color: "#ffbb33" }} />
      //   ) : battery > 30 && battery <= 50 ? (
      //     <Battery50 style={{ color: "#00C851" }} />
      //   ) : battery > 50 && battery <= 60 ? (
      //     <Battery60 style={{ color: "#00C851" }} />
      //   ) : battery > 60 && battery <= 80 ? (
      //     <Battery80 style={{ color: "#00C851" }} />
      //   ) : (
      //     <BatteryFull style={{ color: "#00C851" }} />
      //   )}
      //   {" " + battery + "%"}
      // </Typography>


  );
}

Battery.propTypes = {
  battery: PropTypes.number.isRequired,
};
