import React, { useEffect, useState } from "react";
import "./index.css";
import darkTheme from "./themes/darkTheme";
import lightTheme from "./themes/lightTheme";
import { AppContextProvider, useAppContext } from "./context/AppContext";
import {
  SocketContextProvider,
  // useSocketContext,
} from "./context/SocketContext";
import Routes from "./components/Routes";
import TopNavigation from "./components/Navigation/TopNavigation";
import SideNavigation from "./components/Navigation/SideNavigation";
import ModalLogout from "./components/Login/DialogLogout";
// import Chat from "./components/SharedComponents/Chat";
import BackgroundSlider from "react-background-slider";

import Notifications from "./components/SharedComponents/Notifications";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import { useDispatch } from "react-redux";
import authActions from "./redux/actions/auth";
import actionsUtils from "./redux/actions/utils";
import { SnackbarProvider } from "notistack";

const wallpapers = [
  "https://images.alphacoders.com/681/thumb-1920-681458.jpg",
  "https://images8.alphacoders.com/600/thumb-1920-600397.jpg",
  "https://images4.alphacoders.com/601/thumb-1920-601562.jpg",
  "https://images4.alphacoders.com/103/thumb-1920-1038769.jpg",
  "https://images8.alphacoders.com/589/thumb-1920-589838.jpg",
];

function App() {
  const { loged, logoutDialog, themeColor, transporterID } = useAppContext();
  const [redirect] = useState(<div />);

  const dispatch = useDispatch();


  useEffect(() => {
    const fetch = async () => {
      dispatch(actionsUtils.fetchDispositions());
      dispatch(actionsUtils.fetchDataTransporter(transporterID));
      dispatch(actionsUtils.fetchLocals());
    };
    fetch();
  });
  return (
    <MuiThemeProvider theme={themeColor === "light" ? lightTheme : darkTheme}>
      <CssBaseline>
        {redirect}
        {!logoutDialog && (
          <div>
            <TopNavigation />
            {themeColor === "light" && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: -10,
                }}
              >
                <BackgroundSlider
                  images={wallpapers}
                  duration={20}
                  transition={2}
                />
              </div>
            )}
            <div style={{ paddingTop: 60 }}>
              {loged && <SideNavigation />}
              <main
                id="content"
                style={{
                  padding: "2%",
                }}
              >
                <Routes style={{ zIndex: 1000 }} />
                {/* {loged && <Chat />} */}
              </main>
            </div>
            <Notifications />
          </div>
        )}
        {logoutDialog && <ModalLogout />}
      </CssBaseline>
    </MuiThemeProvider>
  );
}
const Index = () => (
  <AppContextProvider>
    <SocketContextProvider>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </SocketContextProvider>
  </AppContextProvider>
);

export default Index;

//cienceKitLearn
