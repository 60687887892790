import React from "react";
import {
  Grid,
  TextField,
  Button,
  makeStyles,
  ButtonGroup,
  InputAdornment,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import PasswordForm from "./PasswordForm";
import UserDataForm from "./UserDataForm";
import GeneralDataForm from "./GeneralDataForm";
import regProvComsData from "../../../../../resources/regProvCom";
import { useProfileContext } from "../../../../../context/ProfileContext";

const useStyles = makeStyles({
  root: {
    border: "2px #45cafc",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `#303f9f !important`,
    },
  },
  notchedOutline: {
    borderWidth: "2px",
    borderColor: "#303f9f !important",
  },
  focused: {
    borderWidth: "2px",
    borderColor: "#303f9f !important",
  },
});

const PasswordFieldStyled = ({
  value,
  name,
  error,
  label,
  onChange,
  adornment,
}) => {
  const styles = useStyles();
  return (
    <TextField
      margin="dense"
      variant="outlined"
      fullWidth
      type="password"
      name={name}
      label={label}
      value={value}
      error={value?.length < 7 && error}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={
        !error
          ? {
            classes: {
              root: styles.cssOutlinedInput,
              notchedOutline: styles.notchedOutline,
            },
            startAdornment: (
              <InputAdornment position="start">{adornment}</InputAdornment>
            ),
            readOnly: false,
          }
          : {
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">{adornment}</InputAdornment>
            ),
          }
      }
    />
  );
};

export default function ModalCreate() {
  const {
    profileData,
    changePassword,
    updateProfileData,
  } = useProfileContext();
  const [error, setError] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [changePass, setChangePass] = React.useState(false);
  const [communes, setCommunes] = React.useState([]);

  const [formData, setFormData] = React.useState({
    localName: "-",
    localCommune: "LAS_CONDES",
    localAddress: "-",
    userName: "-",
    userLastName: "-",
    userPhone: "-",
    userEmail: "-",
    password: "",
    newPassword: "",
    newPasswordRpt: "",
  });

  React.useEffect(() => {
    if (profileData) {
      setFormData({
        localName: profileData.localName,
        localAddress: profileData.localAddress,
        localCommune: profileData.localCommune,
        userName: profileData.userName,
        userLastName: profileData.userLastName,
        userEmail: profileData.userEmail,
        userPhone: profileData.userPhone,
        password: "",
        newPassword: "",
        newPasswordRpt: "",
      });
    }
  }, [profileData]);

  React.useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 1000);
    }
  }, [error]);

  // REGIONES PROVINCIAS Y COMUNAS
  React.useEffect(() => {
    regProvComsData.forEach(regionsElements => {
      regionsElements.provincias.forEach(provinceElement => {
        provinceElement.comunas.forEach(communeElement => {
          communes.push({
            name: communeElement.name,
            code: communeElement.code
          })
        })
      })
    })
  }, [])

  const setOption = (option) => {
    if (option === "EDIT") {
      if (edit) {
        setEdit(false);
      } else {
        setEdit(true);
        setChangePass(false);
      }
    } else {
      if (changePass) {
        setChangePass(false);
      } else {
        setChangePass(true);
        setEdit(false);
      }
    }
  };

  const updateData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSuccessEdit = () => {
    if (
      formData.localName &&
      formData.localAddress &&
      formData.userName &&
      formData.userLastName &&
      formData.userEmail &&
      formData.userPhone &&
      formData.password.length > 6
    ) {
      updateProfileData(formData).finally((response) => setEdit(false));
    } else {
      setError(true);
    }
  };

  const onSuccessPass = () => {
    if (
      formData.password.length > 6 &&
      formData.newPassword.length > 6 &&
      formData.newPasswordRpt === formData.newPassword
    ) {
      changePassword({
        password: formData.password,
        newPassword: formData.newPassword,
      }).finally((response) => setChangePass(false));
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <Grid container spacing={2} justify="flex-end">
        <Grid item>
          <ButtonGroup size="small">
            <Button
              color="primary"
              startIcon={<LockIcon />}
              variant={!changePass ? "contained" : "outlined"}
              onClick={() => setOption("PASS")}
            >
              cambiar contraseña
            </Button>
            <Button
              color="primary"
              startIcon={<EditIcon />}
              variant={!edit ? "contained" : "outlined"}
              onClick={() => setOption("EDIT")}
            >
              editar información
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      {changePass && (
        <PasswordForm
          onSuccessPass={onSuccessPass}
          error={error}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      <UserDataForm
        error={error}
        formData={formData}
        setFormData={setFormData}
        edit={edit}
      />
      <GeneralDataForm
        error={error}
        formData={formData}
        setFormData={setFormData}
        edit={edit}
        communes={communes}
      />
      {edit && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <hr></hr>
          </Grid>
          <Grid item xs={12} sm={4} />
          <Grid item xs={12} sm={8}>
            <h5 style={{ textAlign: "center" }}>
              Ingresa tu contraseña para guardar los cambios
            </h5>
          </Grid>
          <Grid item xs={12} sm={4} />
          <Grid item xs={12} sm={4}>
            <PasswordFieldStyled
              value={formData.password}
              name="password"
              label="Contraseña"
              error={error}
              edit={edit}
              onChange={updateData}
              adornment={<LockIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              style={{ marginTop: 10 }}
              onClick={() => onSuccessEdit()}
              startIcon={<CheckCircleOutlineIcon />}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
