import React, { useState } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Typography,
  Button,
  Step,
  Stepper,
  StepLabel,
  StepContent,
  MuiThemeProvider,
  DialogActions,
  createMuiTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";

import materialsList from "../../../../resources/materials";
import removalsActions from "../../../../redux/actions/removals";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#ff4444" },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function DialogBody({ setViewState, removal }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const materials = materialsList.materials;

  const [formData] = useState(removal);

  const windows = {
    A: "entre 9:00 y 13:00 hrs",
    B: "entre 14:00 y 19:00 hrs",
  };

  const handleDeleteRemoval = async (removalID, localID) => {
    await dispatch(removalsActions.deleteRemovalInProgress({ removalID, localID }));
  };

  return (
    <Dialog
      open={true}
      onClose={() => setViewState(false)}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">Resumen del retiro</Typography>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              onClick={() => setViewState(false)}
              size="large"
            >
              <ClearOutlinedIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="center">
          <Grid item xs={10} align="center">
            <hr />
            <LinearStepper status={1} />
          </Grid>
          <Grid item container xs={12} sm={9}>
            <Grid item xs={12}>
              <hr />
              <Typography variant="body2" color="textSecondary">
                Fecha y hora
              </Typography>
              <Typography variant="body1">
                {formData.dateString + ", " + windows[formData.window]}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <hr />
              <Typography variant="body2" color="textSecondary">
                Descripción
              </Typography>
              <Typography variant="body1">{formData.description} </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <img
              src={formData.urlImage}
              style={{ maxWidth: "100%" }}
              alt="imagen del retiro"
            />
          </Grid>
          <Grid item xs={12}>
            <hr></hr>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" style={{ marginBottom: 10 }}>
              Contenedores
            </Typography>
            <TableResume formData={formData} />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" style={{ marginBottom: 10 }}>
              Materiales
            </Typography>
            {formData.materialsExtra.map((material) => {
              return (
                <Chip
                  avatar={
                    <FiberManualRecordIcon
                      style={{
                        color: materials[material.toLowerCase()].color,
                      }}
                    />
                  }
                  label={materials[material.toLowerCase()].name}
                  className={classes.chip}
                  key={"chip_" + material}
                />
              );
            })}
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MuiThemeProvider theme={theme}>
          <Button
            color="primary"
            fullWidth
            startIcon={<DeleteIcon />}
            size="small"
            onClick={() => handleDeleteRemoval(removal._id, removal.localID)}
          >
            Cancelar retiro
          </Button>
        </MuiThemeProvider>
      </DialogActions>
    </Dialog>
  );
}

function TableResume({ formData }) {
  const containers = [
    { type: "Pequeño", capacity: 80, key: "littleContainer" },
    { type: "Mediano", capacity: 120, key: "middleContainer" },
    { type: "Grande", capacity: 500, key: "bigContainer" },
    { type: "Gigante", capacity: 1000, key: "giantContainer" },
  ];
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>Contenedor</strong>
          </TableCell>
          <TableCell>
            <strong>Cantidad</strong>
          </TableCell>
          <TableCell>
            <strong>Volumen aproximado</strong>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {containers.map((container, i) => {
          if (formData.containers[container.key]) {
            return (
              <TableRow key={"container_" + i}>
                <TableCell>{container.type}</TableCell>
                <TableCell>{formData.containers[container.key]}</TableCell>
                <TableCell>
                  {formData.containers[container.key] * container.capacity}{" "}
                  Litros
                </TableCell>
              </TableRow>
            );
          }
        })}
      </TableBody>
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>Total</strong>
          </TableCell>
          <TableCell></TableCell>
          <TableCell>
            <strong>
              {formData.containers.littleContainer * 80 +
                formData.containers.middleContainer * 120 +
                formData.containers.bigContainer * 500 +
                formData.containers.giantContainer * 1000}{" "}
              Litros
            </strong>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
}

const steps = [
  { name: "Solicitud", content: "Porcesando solicitud" },
  { name: "Transportista", content: "Buscando óptimo" },
  { name: "Recolección", content: "Ejecutando retiro" },
  { name: "Materiales", content: "Esperando reporte de reciclaje" },
];

function LinearStepper({ status }) {
  return (
    <Stepper activeStep={status} orientation="horizontal">
      {steps.map((step, i) => (
        <Step key={i}>
          <StepLabel>{step.name}</StepLabel>
          <StepContent>{step.content}</StepContent>
        </Step>
      ))}
    </Stepper>
  );
}

LinearStepper.propTypes = {
  status: PropTypes.number.isRequired,
};

TableResume.propTypes = {
  formData: PropTypes.object.isRequired,
};

DialogBody.propTypes = {
  setViewState: PropTypes.func.isRequired,
  removal: PropTypes.object.isRequired,
};
