import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
  Grid,
  Card,
  CardContent,
  Box,
  TextField,
  useTheme,
  makeStyles,
  useMediaQuery
} from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import Jimp from "jimp";


import { useDispatch } from "react-redux";
import { useAppContext } from "../../../../context/AppContext";
import removalActions from "../../../../redux/actions/removals";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  selected: {
    background:"rgb(0, 200, 81)",
    color: "#fff",
    fontSize: "0.85em"
  },
  fail: {
    background:"rgb(255, 0, 0, 92%)",
    color: "#fff",
    fontSize: "0.85em"
  },
}));

export default function TakeModal({
  takeRemovalScheduler,
  datetimeRemoval,
  setTakeRemovalScheduler,
  responseRemovalList,
}) {
  const dispatch = useDispatch();
  const { userType, userID  } = useAppContext();
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [error, setError] = React.useState(false);
  const [formData, setFormData] = useState({
    datetimeRemoval: "0",
    window: "A",
    description: "",
    image: "",
  });

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  const handleSetImages = (event) => {
    Array.from(event.target.files).forEach((file) => {
      setTimeout(() => {
        Jimp.read(URL.createObjectURL(file))
          .then((photo) => {
            photo
              .contain(800, 800)
              .quality(100)
              .getBase64Async(Jimp.MIME_JPEG)
              .then((res) => {
                setFormData({ ...formData, image: res });
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => {
            console.error(err);
          });
      }, 1000);
    });
  };

 

  const handleClose = () => {
    setTakeRemovalScheduler(false);
  };

  const success = async (removal) => {
    formData.datetimeRemoval = datetimeRemoval;
    
      await dispatch(removalActions.agree( userID, removal));
      setTakeRemovalScheduler(false);
    
  };

  const deny = async (removal) => {
    formData.datetimeRemoval = datetimeRemoval;
    
      await dispatch(removalActions.deny( userID, removal));
      setTakeRemovalScheduler(false);
    
  };

  const daySelected = () => {
    return datetimeRemoval.format("DD");
  }
  const currentMonthName = () => {
    return datetimeRemoval.format("MMMM");
  }

  return (
    <Dialog
      open={takeRemovalScheduler}
      aria-labelledby="form-dialog-title"
      fullScreen={isMobile}
      scroll="body"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Retiros del día {daySelected()} de {currentMonthName()} </DialogTitle>
      <DialogContent>
        <DialogContentText>
          De confirmar, su retiro quedará agendado y no podrá ser cancelado antes de las 48 horas previas a su ejecución.
      </DialogContentText>
      {
        responseRemovalList.map((removal, idx) => (
        <Box boxShadow={2}>
        <Card>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid container spacing={1}>
              
              
              <Grid item xs={6}>
			  
			 
			  
             Este retiro está programado para la ventana horaria {removal.window=='A'? '9:00 a 13:00' :'14:00 a 19:00'} y tiene un pago de ${removal.payment}.
             <br/>
             Para este retiro debes dirigirte a {removal.localID.address}, comuna de {removal.localID.commune}.
              </Grid>
              <Grid item xs={3}>
                <Button variant={'contained'} color={'primary'} className={classes.fail} onClick={() => deny(removal)}>
                  Rechazar
                </Button>
                </Grid>
                <Grid item xs={3}>
                <Button variant={'contained'} color={'primary'} className={classes.selected} onClick={() => success(removal)}>
                  Aceptar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          </Card>
    </Box>
        ))
      }
      </DialogContent>
      <DialogActions>
      <Button onClick={handleClose} color="primary">
          Cancelar
      </Button>
      </DialogActions>
    </Dialog>
  );
}