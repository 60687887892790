import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Chip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import materialsList from "../../../../../resources/materials";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Resume({ formData }) {
  const classes = useStyles();
  const materials = materialsList.materials;

  const windows = {
    A: "entre 9:00 y 13:00 hrs",
    B: "entre 14:00 y 19:00 hrs",
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Resumen del retiros</Typography>
      </Grid>
      <Grid item container xs={12} sm={9}>
        <Grid item sm={12} xs={12}>
          <TextField
            fullWidth
            value={formData.dateString + ", " + windows[formData.window]}
            margin="dense"
            variant="outlined"
            label="Fecha y horario"
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
            fullWidth
            value={formData.description}
            margin="dense"
            variant="outlined"
            multiline
            rows={6}
            label="Descripción"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <img
          src={formData.image}
          style={{ maxWidth: "100%" }}
          alt="imagen del retiro"
        />
      </Grid>
      <Grid item xs={12}>
        <hr></hr>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h6" style={{ marginBottom: 10 }}>
          Contenedores
        </Typography>
        <TableResume formData={formData} />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6" style={{ marginBottom: 10 }}>
          Materiales
        </Typography>
        {Object.keys(formData.materials).map((chip) => {
          if (formData.materials[chip]) {
            return (
              <Chip
                avatar={
                  <FiberManualRecordIcon
                    style={{ color: materials[chip].color }}
                  />
                }
                label={materials[chip].name}
                className={classes.chip}
                key={"chip_" + chip}
              />
            );
          }
        })}
      </Grid>
    </Grid>
  );
}

function TableResume({ formData }) {
  const containers = [
    { type: "Pequeño", capacity: 80, key: "littleContainer" },
    { type: "Mediano", capacity: 120, key: "middleContainer" },
    { type: "Grande", capacity: 500, key: "bigContainer" },
    { type: "Gigante", capacity: 1000, key: "giantContainer" },
  ];
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>Contenedor</strong>
          </TableCell>
          <TableCell>
            <strong>Cantidad</strong>
          </TableCell>
          <TableCell>
            <strong>Volumen aproximado</strong>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {containers.map((container, i) => {
          if (formData.containers[container.key]) {
            return (
              <TableRow key={"container_" + i}>
                <TableCell>{container.type}</TableCell>
                <TableCell>{formData.containers[container.key]}</TableCell>
                <TableCell>
                  {formData.containers[container.key] * container.capacity}{" "}
                  Litros
                </TableCell>
              </TableRow>
            );
          }
        })}
      </TableBody>
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>Total</strong>
          </TableCell>
          <TableCell></TableCell>
          <TableCell>
            <strong>
              {formData.containers.littleContainer * 80 +
                formData.containers.middleContainer * 120 +
                formData.containers.bigContainer * 500 +
                formData.containers.giantContainer * 1000}{" "}
              Litros
            </strong>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
}

Resume.propTypes = {
  formData: PropTypes.object.isRequired,
};

TableResume.propTypes = {
  formData: PropTypes.object.isRequired,
};
