import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import ReduxThunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

import reports from "./reducers/reports";
import utils from "./reducers/utils";
import removals from "./reducers/removals";
import notifies from "./reducers/notifies";
import sensors from "./reducers/sensors";
import learning from "./reducers/learning";
import locals from "./actions/locals";
import { createBrowserHistory } from "history";

const createRootReducer = (history) =>
  combineReducers({
    reports,
    utils,
    sensors,
    removals,
    notifies,
    learning,
    locals
  });

// * -------- Estado inicial de la aplicación
const initialState = {};

// * -------- Middlewares
export const history = createBrowserHistory();
const middlewares = [ReduxThunk, routerMiddleware(history)];

// * -------- Enhancers del store --------
const enhancers = [];

// * -------- Componer Middlewares y Enhancers --------
let composedEnhancers = null;
if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
  const composeEnhancers = composeWithDevTools({ trace: true });
  composedEnhancers = composeEnhancers(
    applyMiddleware(...middlewares),
    ...enhancers
  );
} else {
  composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers);
}

// * -------- Configuración del Persist --------
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["router"],
  whitelist: [""],
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

export default function configureStore() {
  const store = createStore(persistedReducer, initialState, composedEnhancers);
  const persistor = persistStore(store);
  return { store, persistor };
}
