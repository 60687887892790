import React from "react";
import { useAppContext } from "../../../context/AppContext";
import LocalProfile from "./Local";
import CustomerProfile from "./Costumer";
import TransporterProfile from "./Transporter";
import { ProfileContextProvider } from "../../../context/ProfileContext";

function Index() {
  const { userType } = useAppContext();

  return (
    <>
      {userType === "LOCAL" && <LocalProfile />}
      {userType === "CUSTOMER" && <CustomerProfile />}
      {userType === "TRANSPORTER" && <TransporterProfile/>}
    </>
  );
}

export default function Profile() {
  return (
    <ProfileContextProvider>
      <Index />
    </ProfileContextProvider>
  );
}
