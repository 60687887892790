import React from "react";
import { Grid, Button } from "@material-ui/core";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import PropTypes from "prop-types";

export default function Buttons({ removal, viewState, setViewState }) {
  return (
    <Grid item xs={12}>
      <ButtonState
        viewState={viewState}
        setViewState={setViewState}
        removal={removal}
      />
    </Grid>
  );
}

function ButtonState({ setViewState, viewState }) {
  return (
    <Button
      fullWidth
      color="primary"
      variant="text"
      onClick={() => setViewState(!viewState)}
      startIcon={
        viewState ? <VisibilityOffIcon /> : <RemoveRedEyeOutlinedIcon />
      }
    >
      estado
    </Button>
  );
}

ButtonState.propTypes = {
  viewState: PropTypes.object.isRequired,
  setViewState: PropTypes.func.isRequired,
};

Buttons.propTypes = {
  removal: PropTypes.object.isRequired,
  viewState: PropTypes.bool.isRequired,
  setViewState: PropTypes.func.isRequired,
};
