import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from "@material-ui/icons/Done";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import LocalShipping from "@material-ui/icons/LocalShipping";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PropTypes from "prop-types";

const days = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];
const months = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

const windows = {
  A: "entre 9:00 y 13:00 hrs",
  B: "entre 14:00 y 19:00 hrs",
};

export default function Header({ status, removal, ratingTemp }) {
  const blueBackgorund =
    "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)";
  const greenBackgorund =
    "linear-gradient(90deg, rgba(0,135,54,1) 0%, rgba(0,255,103,1) 60%)";
  const yellowBackgorund =
    "linear-gradient(112deg, rgba(255,180,0,1) 28%, rgba(247,255,112,1) 79%)";
  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          {status === 1 ? (
            <Typography component="h2" variant="h6">
              Retiro en curso
            </Typography>
          ) : status === 2 ? (
            <Typography component="h2" variant="h6">
              Retiro agendado
            </Typography>
          ) : status === 3 ? (
            <Typography component="h2" variant="h6">
              Retiros rechazado
            </Typography>
          ) : status === 4 ? (
            <Typography component="h2" variant="h6">
              Retiro en curso
            </Typography>
          ) : status === 5 ? (
            <Typography component="h2" variant="h6">
              Retiro completado
            </Typography>
          ) : status === 7 ? (
              <Typography component="h2" variant="h6">
                Retiro completado
              </Typography>
          ): status === 8 ? (
            <Typography component="h2" variant="h6">
                Retiro sin material
              </Typography>
             
          ): (
            <Typography component="h2" variant="h6" color="textSecondary">
              Retiro disponible
            </Typography>
          )}
          {(status === 4 && removal.author=="WEBAPP_EXTRA") ? (
          <Typography variant="body2" align="center" color="textSecondary">
            (Retiro extra)
          </Typography>
          ) : (
            <Typography variant="body2" align="center" color="textSecondary">
            <br/>
          </Typography>
          )}

            {( removal.statusTransport=="PENDING_VALID") ? (
              <Typography variant="body2" align="center">
               (Pendiente por validar)
              </Typography>

              ):(

              <br/>
              )}
        </Grid>
        <Grid item>
          {status === 1 ? (
            <DateRangeIcon style={{ color: "#0099CC" }} />
          ) : status === 2 ? (
            <EventAvailableIcon style={{ color: "#00C851" }} />
          ) : status === 3 ? (
            <DoneAllIcon style={{ color: "#00C851" }} />
          ) : status === 4 && removal.rating ? (
            <DoneAllIcon style={{ color: "#00C851" }} />
          ) : status === 4 && !removal.rating && !ratingTemp ? (
            <DoneAllIcon style={{ color: "#00C851" }} />
          ) : status === 4 && !removal.rating && ratingTemp ? (
            <DoneAllIcon style={{ color: "#ffb400" }} />
          ) : status === 5 ? (
            <DoneIcon style={{ color: "#0099CC" }} />
          ) : status === 7 ? (
            <DoneIcon style={{ color: "#0099CC" }} />
          ) : status === 8 ? (
            <DoneIcon style={{ color: "#0099CC" }} />
          ): (
            <ScheduleOutlinedIcon style={{ color: "#BDBDBD" }} />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {status === 1 ? (
            <hr style={{ background: blueBackgorund, height: 2 }} />
          ) : status === 2 ? (
            <hr style={{ height: 2, background: greenBackgorund }} />
          ) : status === 3 ? (
            <hr style={{ height: 2, background: greenBackgorund }} />
          ) : status === 4 && removal.rating ? (
            (removal.author=="WEBAPP_EXTRA"?<hr style={{ height: 2, background: yellowBackgorund }} />:<hr style={{ height: 2, background: greenBackgorund }} />)
          ) : status === 4 && !removal.rating && !ratingTemp ? (
            (removal.author=="WEBAPP_EXTRA"?<hr style={{ height: 2, background: yellowBackgorund }} />:<hr style={{ height: 2, background: greenBackgorund }} />)
          ) : status === 4 && !removal.rating && ratingTemp ? (
            (removal.author=="WEBAPP_EXTRA"?<hr style={{ height: 2, background: yellowBackgorund }} />:<hr style={{ height: 2, background: greenBackgorund }} />)
          ) : status === 5 ? (
            <hr style={{ height: 2, background: blueBackgorund }} />
          ) : status === 7 ? (
              <hr style={{ height: 2, background: greenBackgorund }} />
          ) : status === 8 ? (
            <hr style={{ height: 2, background: greenBackgorund }} />
          ) : (
            <hr color="#BDBDBD" style={{ height: 2 }} />
          )}
       
          {status === 6 ? (
            <TextField
              margin="dense"
              value={new Intl.DateTimeFormat("es-CL", {
                year: "numeric",
                month: "long",
                day: "2-digit",
              }).format(new Date(removal.datetimeRemoval))}
              InputProps={{
                readOnly: true,
              }}
              disabled={status === 6}
              variant="outlined"
              label={"Fecha óptima"}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : (
            <div>
              <Typography variant="body2" align="center" color="textSecondary">
                {status === 1 && "Fecha y horario solicitado"}
                {status === 2 && "Fecha y horario establecido"}
                {status === 3 && "Fecha y horario establecido"}
                {(status === 4 || status == 8|| status == 5|| status == 7) && "Fecha y horario de retiro"}
              </Typography>
              <hr />
              <Typography variant="body2" align="center">
                {days[new Date(removal.datetimeRemoval).getDay()] +
                  " " +
                  new Date(removal.datetimeRemoval).getDate() +
                  " de " +
                  months[new Date(removal.datetimeRemoval).getMonth()] +
                  " " +
                  new Date(removal.datetimeRemoval).getFullYear()}
              </Typography>
              <Typography variant="body2" align="center">
                {windows[removal.window]}
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

Header.propTypes = {
  removal: PropTypes.object.isRequired,
  status: PropTypes.number.isRequired,
  ratingTemp: PropTypes.number.isRequired,
};
