const enqueueNotify = (type) => {
  return (dispatch) => {
    switch (type) {
      case "REMOVAL_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro solicitado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      
      case "REMOVAL_VEHICLEID_NULL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Debe ingresar un vehículo",
            severity: "error",
          },
        });
        return true;
      }
      
      
      case "REMOVAL_MATERIALS_NULL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Debe indicar si hubo material",
            severity: "error",
          },
        });
        return true;
      }
      
      case "REMOVAL_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo solicitar el retiro",
            severity: "error",
          },
        });
        return true;
      }
      case "REMOVAL_SET_RATING_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Valoración registrada",
            severity: "success",
          },
        });
        return true;
      }
      case "REMOVAL_SET_RATING_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se puedo registrar la valoración",
            severity: "error",
          },
        });
        return true;
      }
      case "REMOVAL_EXTRA_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro extra solicitado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "REMOVAL_EXTRA_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo solicitar el retiro extra",
            severity: "error",
          },
        });
        return true;
      }
      case "REMOVAL_DELETE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro cancelado exitosamente",
            severity: "success",
          },
        });
        return true;
      }
      case "REMOVAL_DELETE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo cancelar el retiro",
            severity: "error",
          },
        });
        return true;
      }
      case "REMOVAL_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro registrado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "REMOVALS_SET_DATA_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro registrado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      default: {
        return false;
      }
    }
  };
};

const dequeueNotify = () => {
  return (dispatch) => dispatch({ type: "NOTIFY_DEQUEUE" });
};

export default { enqueueNotify, dequeueNotify };
