import React from "react";
import Axios from "../api/Axios";

const LocalsContext = React.createContext();

export function LocalsContextProvider(props) {
  const [locals, setLocals] = React.useState([]);

  React.useEffect(() => {
    getLocals();
  }, []);

  const getLocals = async () => {
    const data = await getLocalsFunction();
    if (data.data) {
      setLocals(data.data);
    }
  };

  const value = React.useMemo(() => {
    return {
      locals,
    };
  }, [locals]);

  return <LocalsContext.Provider value={value} {...props} />;
}

export function useLocalsContext() {
  const context = React.useContext(LocalsContext);
  if (!context) {
    throw new Error("No suscrito al contexto");
  }
  return context;
}

const getLocalsFunction = async () => {
  try {
    return await Axios.get("/webapp/locals");
  } catch (e) {
    return e;
  }
};