import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import Typography from "@material-ui/core/Typography";
import moment from 'moment';
import 'moment/locale/es';
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

moment.locale('es');

const useStyles = makeStyles((theme) => ({
  calendar: {
    boxSizing: "border-box",
    fontSize: "1rem",
    // maxWidth: "400px",
    width: '100%',
    height: '100%',
  },
  header: {
    backgroundColor: "#2f3f9f",
    textAlign: "center",
    minHeight: '2rem',
    lineHeight: '2rem',
    color: "white",
    fontFamily: 'var(--font-book)',

    border: '2px solid #ededed',
    borderRadius: '7px 7px 0px 0px',
    borderLeft: "1px solid #b5b5b5",
    borderBottom: '1px solid #b5b5b5',
  },
  dayNames: {
    backgroundColor: "white",
    width: "calc(100% / 7)",
    textAlign: 'center',
    textTransform: "uppercase",
    color: "var(--black)",
    fontWeight: 400,

    borderLeft: '2px solid #b5b5b5',
    borderBottom: "2px solid #b5b5b5",
  },
  weekHeader: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    margin: "0 auto",
    alignItems: "center",
    textAlign: "center",
    padding: 0,
  },
  body: {
    border: "1px solid var(--light- blue)",
  },
  before: {
    minHeight: '5rem',
    backgroundColor: "rgb(219, 219, 219)",
    color: "rgb(237, 236, 232)",
    cursor: "not-allowed",
  },
  selected: {
    backgroundColor: "var(--blue)",
    color: "var(--white)",

    borderRadius: "50%",
    width: "20%",
    margin: "auto",
    borderLeft: '3px solid var(--blue)',
    borderRight: '3px solid var(--blue)',
    borderBottom: "3px solid var(--blue)",
    borderTop: "3px solid var(--blue)",

  },
  today: {
    backgroundColor: "rgb(166, 215, 255)",
    borderRadius: "50%",
    width: "20%",
    margin: "auto",

    borderLeft: '3px solid #82c7ff',
    borderRight: '3px solid #82c7ff',
    borderBottom: "3px solid #82c7ff",
    borderTop: "3px solid #82c7ff",
    cursor: "not-allowed",

  },
  nextMonth: {
    minHeight: '5rem',
    backgroundColor: "rgb(219, 219, 219)",
    color: "rgb(237, 236, 232)",
    cursor: "not-allowed",
  },
  saturdayOrSunday: {
    minHeight: '5rem',
    backgroundColor: "rgb(219, 219, 219)",
    color: "rgb(237, 236, 232)",
    cursor: "not-allowed",
  },
  fortyEightHours: {
    backgroundColor: "rgb(166, 215, 255)",
    borderRadius: "50%",
    width: "20%",
    margin: "auto",
    borderLeft: '3px solid #82c7ff',
    borderRight: '3px solid #82c7ff',
    borderBottom: "3px solid #82c7ff",
    borderTop: "3px solid #82c7ff",
    color: "white",
    cursor: "not-allowed",
  },
  available: {
    background: "linear-gradient(74deg, rgba(48, 63, 159, 1) 21%, rgba(69, 202, 252, 0.9) 100%)",
    color: "white",
    position: 'absolute',
    right: 0,
    bottom: 0,
    minWidth: 0,
    fontSize: '0.8rem',
    borderLeft: "2px solid #787878",
    borderRight: '2px solid #787878',
    borderBottom: '2px solid #787878',
  },
  day: {
    position: 'relative',
    width: 'calc(100% / 7)',
    minHeight: '5rem',
    display: "inline-block",
    backgroundColor: 'white',
    padding: 0,
    margin: 0,
    boxSizing: "border-box",
    zIndex: 1,
    textAlign: "center",
    paddingTop: 0,

    borderLeft: '2px solid #787878',
    borderRight: '2px solid #787878',
    borderBottom: "2px solid #787878",
  },
}));

const days = ["Lu", "Ma", "Mi", "Ju", "Vi", "Sa", "Do"]

export default function SchedulerTransporter({
  removals,
  setDatetimeRemoval,
  setResponseRemovalList,
  setTakeRemovalScheduler,
}) {
  const classes = useStyles();
  const [valueDay, setValueDay] = useState(moment());

  const startDay = valueDay.clone().startOf("month").startOf("week");
  const endDay = valueDay.clone().endOf("month").endOf("week");
  const day = startDay.clone().subtract(1, "day");

  const calendar = [];

  while (day.isBefore(endDay, "day")) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, "day").clone()))
  }

  const isRemovalDay = (day) => {
    let count = 0;
    for (var i = 0; i < removals.length; ++i) {
      if (day.isSame(new Date(removals[i].datetimeRemoval), "day") ) {
        count++;
      }
    }
    return count;
  }

  const listRemovalDay = (day) => {
    let listRemoval = [];
    for (var i = 0; i < removals.length; ++i) {
      if (day.isSame(new Date(removals[i].datetimeRemoval), "day") ) {
        listRemoval.push(removals[i]);
      }
    }
    return listRemoval;
  }

  const isFortyEightHours = (day) => {
    const now = new Date();
    if (now.getDate() - day.format("D") == 0 || now.getDate() - day.format("D") == -1) {
      return true
    }
    return false
  }

  const isSelectable = (day) => {
    if (!beforeToday(day) && !isNextMonth(day) && isThisMonth(day) && !isSaturdayOrSunday(day) && !isFortyEightHours(day)) {
      return true
    }
    return false
  }

  // init STYLE CALENDAR  re(ciben (day, valueDay)
  const isSelected = (day) => {
    return valueDay.isSame(day, "day");
  }
  const beforeToday = (day) => {
    return day.isBefore(new Date(), "day");
  }

  const isToday = (day) => {
    return day.isSame(new Date(), "day");
  }

  const isNextMonth = (day) => {
    return day.isAfter(new Date(), "month");
  }

  const isThisMonth = () => {
    return valueDay.isSame(new Date(), "month");
  }
  const isSaturdayOrSunday = (day) => {
    if (new Date(day).getDay() == 0 || new Date(day).getDay() == 6) {
      return true
    }
    return false
  }

  const dayStyles = (day) => {
    if (isSaturdayOrSunday(day)) return classes.saturdayOrSunday;
    if (isToday(day)) return classes.today;
    if (isSelected(day)) return classes.selected;
    if (isNextMonth(day)) return classes.nextMonth;
    return "";
  }
  // end STYLE CALENDAR

  //  init HEADER 
  const currentMonthName = () => {
    return valueDay.format("MMMM");
  }
  const currentYear = () => {
    return valueDay.format("YYYY");
  }
  // end HEADER 

  const coordinate = (day) => {
    setDatetimeRemoval(day);
    setResponseRemovalList(listRemovalDay(day));
    setTakeRemovalScheduler(true);
  }

  return (
    <Card style={{ padding: 2, margin: 2 }}>
      <Grid container justify="space-between" direction="row">
        <Grid item className={classes.calendar}>
          <Grid item className={classes.header} >
            <Typography variant="h3">
              {currentMonthName().toUpperCase()} {currentYear()}
            </Typography>
          </Grid>
            <Table className={classes.body}>
              <TableHead className={classes.weekHeader}>
                {
                  days.map((day, idx) => (
                    <Grid item key={idx} className={classes.dayNames} >
                      <Typography variant="h5">
                        {day}
                      </Typography>
                    </Grid>
                  ))
                }
              </TableHead>
              <TableBody className={classes.body}>
                {calendar.map((week, idx) => (
                  <TableRow item key={idx}>
                  {
                    week.map((day, idy) => (
                      <TableCell
                        item
                        className={classes.day}
                        key={idy}
                        onClick={() => coordinate(day)}
                        onDoubleClick={() =>  coordinate(day)}
                      >
                        <Grid item className={dayStyles(day)}>
                          <Typography component="h3" >
                            {day.format("D").toString()}
                          </Typography>
                        </Grid>
                        { isRemovalDay(day)!=0 &&
                          <Button
                            fullWidth
                            variant="contained"
                            className={classes.available}
                            onClick={() => coordinate(day)}
                          >
                            
                            {isRemovalDay(day)} Retiro{isRemovalDay(day)>0?"s":""}
                          </Button>
                        }
                      </TableCell>
                    ))
                  }
                </TableRow>
                ))
                }
            </TableBody>
            </Table>
        </Grid>
      </Grid>
    </Card>
  )
}