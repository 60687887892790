import React from "react";
import Axios from "../../api/Axios";
import { TextField, Button, Paper, makeStyles } from "@material-ui/core";
import colorLogo from "../../resources/images/logo-color.png";
import whiteLogo from "../../resources/images/logo.png";

import { useAppContext } from "../../context/AppContext";
const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: spacing(3),
    marginRight: spacing(3),
    [breakpoints.up(400 + spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
    borderRadius: "6px",
  },
  paper: {
    marginTop: spacing(18),
    marginBottom: spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${spacing(2)}px ${spacing(3)}px ${spacing(3)}px`,
    borderRadius: "8px",
  },
}));



export default function Login({}) {
  const styles = useStyles();

  const { loginStatus, themeColor } = useAppContext();
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });

  // const updateData = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const handleEnterPress = (event) => {
    // if (event.key === "Enter") {
    //   login({ email: formData.email, password: formData.password });
    // }
  };

  const goback  = async () => {

    var red = window.location.origin + "/login";
    window.location.href = red;
  
  }

  const recovery  = async () => {
    console.log("asdasdasdasdas");
    try {
      var email = document.getElementById("email").value;
      var emailR = document.getElementById("emailR").value;
      // let response = await Axios.post("/auth/login", { email, password });
      
      if(email.length > 0 || emailR.length > 0){
        if(email == emailR){
        let response = await Axios.post("/auth/recover", { email });
        console.log(response);
          if(response.data.success == 1){
            alert("Se ha enviado las instrucciones a tu correo.");
            var red = window.location.origin + "/login";
            window.location.href = red;
          } else {
            alert(response.data.message);
          }      
        } else {
          alert("Los correos ingresados son distintos.");
        }
      } else {
        alert("Todos los campos son obligatorios.");
      }
  
    } catch (error) {
      console.log(error);
      alert("Hubo un problema al recuperar tu contraseña.");
    }
  };

  return (
    <div
      style={{
        top: 0,
        left: 0,
        position: "absolute",
        height: "100%",
        width: "100%",
      }}
      onKeyPress={handleEnterPress}
    >
      <main className={styles.main}>
        <Paper className={styles.paper}>
          <img
            src={themeColor === "light" ? colorLogo : whiteLogo}
            style={{ height: 100, margin: 12 }}
            alt="Logo acción circular"
          />
          <TextField
            name="email"
            id="email"
            label="Correo"
            // autoComplete="email"
            // autoFocus
            variant="outlined"
            fullWidth
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            name="emailR"
            id="emailR"
            label="Repite el Correo"
            // autoComplete="email"
            // autoFocus
            variant="outlined"
            fullWidth
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            fullWidth
            color="primary"
            style={
                  {                    
                    background:
                      "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
                    color: "#fff",
                    marginTop: 14,
                  }
            }
            onClick={recovery}
          >
            Recuperar
          </Button>
          <br></br>
          {/* <p style={{ color: "#303f9f" }}>
            <u>recuperar contraseña</u>
          </p> */}
          <Button
            fullWidth
            color="primary"
            style={
                  {                    
                    background:
                      "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
                    color: "#fff",
                  }
            }
            onClick={goback}
          >
            Volver
          </Button>
        </Paper>
      </main>
    </div>
  );
}
