const defaultState = {
  reports: [],
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "FETCH_REPORTS": {
      return { ...state, reports: payload };
    }

    default:
      return state;
  }
}

export default reducer;
