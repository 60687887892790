import React from "react";
import {
  Grid,
  TextField,
  Button,
  makeStyles,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HrColor from "../../HrColor";

const useStyles = makeStyles({
  root: {
    border: "2px #45cafc",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `#303f9f !important`,
    },
  },
  notchedOutline: {
    borderWidth: "2px",
    borderColor: "#303f9f !important",
  },
});

const PasswordFieldStyled = ({
  value,
  name,
  error,
  label,
  onChange,
  adornment,
}) => {
  const styles = useStyles();
  return (
    <TextField
      margin="dense"
      variant="outlined"
      fullWidth
      type="password"
      name={name}
      label={label}
      value={value}
      error={value?.length < 7 && error}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={
        !error
          ? {
              classes: {
                root: styles.cssOutlinedInput,
                notchedOutline: styles.notchedOutline,
              },
              startAdornment: (
                <InputAdornment position="start">{adornment}</InputAdornment>
              ),
              readOnly: false,
            }
          : {
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">{adornment}</InputAdornment>
              ),
            }
      }
    />
  );
};

export default function PasswordForm({
  onSuccessPass,
  error,
  formData,
  setFormData,
}) {
  const updateData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5">
          Cambiar contraseña
        </Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <PasswordFieldStyled
          value={formData.password}
          name="password"
          label="Contraseña actual"
          error={error}
          onChange={updateData}
          adornment={<LockOpenIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <PasswordFieldStyled
          value={formData.newPassword}
          name="newPassword"
          label="Contraseña nueva"
          error={error}
          onChange={updateData}
          adornment={<LockIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <PasswordFieldStyled
          value={formData.newPasswordRpt}
          name="newPasswordRpt"
          label="Repite contraseña nueva"
          error={error}
          onChange={updateData}
          adornment={<LockIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => onSuccessPass()}
          style={{ marginTop: 8, height: 40 }}
          startIcon={<CheckCircleOutlineIcon />}
        >
          Actualizar
        </Button>
      </Grid>
      <Grid item xs={12}>
        <HrColor />
      </Grid>
    </Grid>
  );
}
