const defaultState = [];

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "NOTIFY_ENQUEUE": {
      return [...state, payload];
    }
    case "NOTIFY_DEQUEUE": {
      let newState = state;
      newState.shift();
      return newState;
    }
    default:
      return state;
  }
}

export default reducer;
