import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  ButtonGroup,
  Tooltip,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SyncIcon from "@material-ui/icons/Sync";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";
import PropTypes from "prop-types";

import Rssi from "../Rssi";
import Battery from "../Battery";
import { useAppContext } from "../../../../context/AppContext";
import LinearProgressFill from "../LinearProgressFill";
import { useSensorsContext } from "../../../../context/SensorsContext";

export default function TableSensors({ setView, setSensor }) {
  const { userType } = useAppContext();
  const { getSensorsTable, helpers } = useSensorsContext();
  const [sensors, setSensors] = React.useState([]);

  React.useEffect(() => {
    getSensorsTable().then((response) => {
      setSensors(response);
    });
  }, [getSensorsTable]);

  const viewSensorFunction = async (data) => {
    await setSensor(data);
    await setView("VIEW_SENSOR");
  };
  //Creacion de un diferencial entre la fecha actual y la fecha de el ultimo updatedAt
  const difference = (date) => {
    const now = new Date();
    const current = new Date(date);
    const diffTime = Math.abs(now - current);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    if (diffDays > 1) {
      return { status: false, hours: diffHours - 1, days: diffDays - 1 };
    } else {
      return { status: true, hours: diffHours - 1, days: diffDays - 1 };
    }
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            {userType === "LOCAL" ? "IMEI" : "Sede / sucursal"}
          </TableCell>
          <TableCell>Nivel</TableCell>
          <TableCell>Batería</TableCell>
          <TableCell>Llenado</TableCell>
          <TableCell>Material</TableCell>
          <TableCell>Última lectura</TableCell>
          <TableCell>Ver más</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sensors.map((row, i) => {
          let diff = difference(row.lastReadDate);
          return (
            <TableRow key={i}>
              <TableCell>
                {userType === "LOCAL" ? row.imei : row.localID.name}
              </TableCell>
              <TableCell>
                {row.rssi >= 0 && row.lastReadDate ? (
                  <Rssi rssi={row.rssi} />
                ) : (
                    <em>No se registra</em>
                  )}
              </TableCell>
              <TableCell>
                {row.battery >= 0 && row.lastReadDate ? (
                  <Battery battery={row.battery} />
                ) : (
                    <em>No se registra</em>
                  )}
              </TableCell>
              <TableCell>
                
                {row.localID.customerID == "63503b70633ef2107109cdbe" ? (
                  row.fill >=70 && row.lastReadDate ? (
                    <em>Lleno</em>
                  ) : (
                    <em>No lleno</em>
                  )
                ):(
                row.fill >= 0 && row.lastReadDate ? (
                    <LinearProgressFill value={row.fill} />
                ) : (
                  <em>No se registra</em>
                )
                )
                }
              </TableCell>
              <TableCell>{helpers[row.material].name}</TableCell>
              <TableCell>
                {row.lastReadDate ? (
                  <Tooltip title={"Hace " + diff.hours + " horas, " + diff.days + " días"}>
                    <div>
                      {diff.status ? (
                        <SyncIcon style={{ color: "#00C851" }} />
                      ) : (
                          <SyncProblemIcon style={{ color: "#ffbb33" }} />
                        )}
                      {new Intl.DateTimeFormat("es-CL", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(new Date(row.lastReadDate))}
                    </div>
                  </Tooltip>
                ) : (
                    <em>No se registra</em>
                  )}
              </TableCell>
              <TableCell>
                <ButtonGroup size="small" variant="outlined">
                  <Button
                    color="primary"
                    onClick={() => viewSensorFunction(row)}
                  >
                    <VisibilityIcon />
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

TableSensors.propTypes = {
  setView: PropTypes.func.isRequired,
  setSensor: PropTypes.func.isRequired,
};
