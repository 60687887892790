import React from "react";
import { Card, CardContent, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  useDashboardContext,
  DashboardContextProvider,
} from "../../../context/DashboardContext";
import CustomerCards from "./CustomerCards";
import LocalCards from "./LocalCards";
import TransporterCards from "./TransporterCards";
import Charts from "./Charts";
import EcoEq from "./Ecoeq";
import Dates from "./Dates";
import CustomerRemovalsList from "./CustomerRemovalsList";
import LocalRemovalsList from "./LocalRemovalsList";
import Header from "../../Navigation/Header";
import ModalViewRemoval from "../Removals/ViewModal";
import Nothing from "./Nothing";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Index() {
  const { dashboardData, userType, selectDate } = useDashboardContext();

  const classes = useStyles();
  const [viewRemoval, setViewRemoval] = React.useState(false);
  const [removal, setRemoval] = React.useState({});

  return (
    <Paper className={classes.root}>
      {viewRemoval && (
        <ModalViewRemoval setViewRemoval={setViewRemoval} removal={removal} />
      )}
      <Card>
        <CardContent>
          <Header />
          <hr />
          <Dates />
          <hr />
          {userType === "CUSTOMER" ? (
            <CustomerCards data={dashboardData} selectDate={selectDate} />
          ) : userType === "TRANSPORTER" ? (
            <TransporterCards data={dashboardData} selectDate={selectDate} />
          ) : (
              <LocalCards data={dashboardData} selectDate={selectDate} />
            )}
          <hr />
          <EcoEq dashboardData={dashboardData} />

          {dashboardData.removals?.length ? (
            <>
              <hr />
              <Charts />
              <hr />
              {userType === "CUSTOMER" ? (
                <CustomerRemovalsList
                  setRemoval={setRemoval}
                  setViewRemoval={setViewRemoval}
                />
              ) : (
                  <LocalRemovalsList
                    setRemoval={setRemoval}
                    setViewRemoval={setViewRemoval}
                  />
                )}
            </>
          ) : (
              <Nothing text={"No hay retiros para mostrar"} />
            )}
        </CardContent>
      </Card>
    </Paper>
  );
}

export default () => (
  <DashboardContextProvider>
    <Index />
  </DashboardContextProvider>
);
