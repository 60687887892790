import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./Login";
import Recovery from "./Login/Recovery";
import ResetPass from "./Login/ResetPass";

import Removals from "./SharedComponents/Removals/index.2";
import Reports from "./SharedComponents/Reports";
import ReportsExcel from "./SharedComponents/ReportsExcel";
import Dashboard from "./SharedComponents/Dashboard";
import Profile from "./SharedComponents/Profile";
import Locals from "./SharedComponents/Locals";
import Sensors from "./SharedComponents/Sensors";
import Learning from "./SharedComponents/Learning";
import { useAppContext } from "../context/AppContext";

export default function Routes() {
  const { loged, userType } = useAppContext();

  const NotMatchLoged = () => {
    return <Redirect to="/" />;
  };
  const NotMatchNotLoged = () => {
    return <Redirect to="/login" />;
  };

  if (loged) {
    return (
      <Switch>
        <Route
          path="/"
          exact
          render={({ match }) => <Dashboard match={match} />}
        />
        <Route
          path="/removals"
          exact
          render={({ match }) => <Removals match={match} />}
        />
        <Route
          path="/profile"
          exact
          render={({ match }) => <Profile match={match} />}
        />
        <Route
          path="/reports"
          exact
          render={({ match }) => <Reports match={match} />}
        />
        
        <Route
          path="/reportsExcel"
          exact
          render={({ match }) => <ReportsExcel match={match} />}
        />
        <Route
          path="/wallpapers"
          exact
          render={({ match }) => <div match={match} />}
        />
        <Route
          path="/sensors"
          exact
          render={({ match }) => <Sensors match={match} />}
        />
        <Route
            path="/learning"
            exact
            render={({ match }) => <Learning match={match} />}
          />
        {userType === "CUSTOMER" && (
          <Route
            path="/locals"
            exact
            render={({ match }) => <Locals match={match} />}
          />
        )}
        <Route component={NotMatchLoged} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route path="/login" render={({ match }) => <Login match={match} />} />
        <Route path="/recovery" render={({ match }) => <Recovery match={match} />} />
        <Route path="/resetpass" render={({ match }) => <ResetPass match={match} />} />
        <Route component={NotMatchNotLoged} />
      </Switch>
    );
  }
}
