import React from "react";
import { makeStyles } from "@material-ui/styles";
import image from "../../../../resources/images/basura.png";
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
const useStyles = makeStyles(({ breakpoints }) => ({
  card: {
    width: "100%",
    maxWidth: 500,
    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    position: "relative",
    overflow: "initial",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    paddingLeft: 8,
    paddingRight: 8,
    background:
      "linear-gradient(34deg, rgba(43,43,43,1) 0%, rgba(50,129,41,1) 29%, rgba(106,255,0,1) 92%)",
    [breakpoints.up("sm")]: {
      textAlign: "left",
      flexDirection: "row-reverse",
    },
  },
  media: {
    flexShrink: 0,
    width: "20%",
    paddingTop: "15%",
    marginLeft: "auto",
    marginRight: "auto",
    [breakpoints.up("sm")]: {
      marginRight: "initial",
    },
  },
  overline: {
    lineHeight: 2,
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: "0.7rem",
    opacity: 0.7,
  },
  heading: {
    fontWeight: "400",
    color: "#ffffff",
    letterSpacing: 0.5,
  },
}));

export default function RewardCard({ value }) {
  const styles = useStyles();
  const [hover, setHover] = React.useState(false);

  return (
    <Card
      className={styles.card}
      style={{ opacity: hover ? 0.7 : 1 }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <CardMedia
        className={styles.media}
        style={{ backgroundSize: "60%" }}
        image={image}
      />
      <CardContent className={styles.content} style={{ padding: 6 }}>
        <Typography variant={"overline"} style={{ color: "#fff" }}>
          MATERIAL RECICLADO
        </Typography>
        <Typography className={styles.heading} variant={"h4"} gutterBottom>
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
}
