import React from "react";
import { Paper, Grid, Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../../Navigation/Header";
import SuscriptionCards from "./Suscriptions";
import Data from "./Data";
import HrColor from "../HrColor";
import Footer from "../Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Admin() {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <Header />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <HrColor />
            </Grid>
            <Grid item xs={12}>
              <SuscriptionCards />
            </Grid>
            <Grid item xs={12}>
              <Data />
              <HrColor />
            </Grid>
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
}